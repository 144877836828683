/**
 * @require ./_base.js
 */
(function (global) {
    /**
     * @augments {DataLoaderBase}
     * @constructor
     */
    var SchedulingLoader = function () {
        var me = this;

        me.Method = 'scheduling';
    };

    SchedulingLoader.prototype = Object.create(global.DataLoaderBase.prototype, {
        constructor: SchedulingLoader
    });

    SchedulingLoader.prototype.Prepare = function () {
        var me = this;

        me.Data.Data = me.Data.Data.map(function (scheduling) {
            return prepare.call(me, scheduling);
        });

        return me.Data.Data;
    };

    function prepare(scheduling) {
        if (!scheduling) {
            return;
        }

        scheduling = Tools.Escape.Scheduling(scheduling);

        var parent = this.Data.DataMap[scheduling.ParentOID];

        if (scheduling.ParentOID && !parent) {
            delete scheduling.ParentOID;
        }

        if (!scheduling.ParentOID) {
            this.Data.Root = scheduling;

            return scheduling;
        }

        if (parent) {
            scheduling.Parent = parent;

            parent.Children = parent.Children || [];
            parent.Children.push(scheduling);
            parent.Children.sort(Tools.SortByPosition);
        }

        return scheduling;
    }

    global.SchedulingLoader = SchedulingLoader;
})(window.Tools || (window.Tools = {}));