/**
 * @require ./elements.js
 * @require ./scheduling.js
 * @require ../misc/enums.js
 */
(function (global) {
    /**
     * @property {ElementLoader} DataManager.OrganizationUnitLoader
     * @property {ElementLoader} DataManager.FormLoader
     * @property {ElementLoader} DataManager.ObjectTemplateLoader
     * @property {ElementLoader} DataManager.CheckpointGroupTemplateLoader
     * @property {ElementLoader} DataManager.MasterdataTemplateLoader
     * @property {SchedulingLoader} DataManager.SchedulingLoader
     * @constructor
     */
    var DataManager = function () {
        this.OrganizationUnitLoader = new Tools.ElementLoader({
            Types: [Enums.elementType.Root, Enums.elementType.Location]
        });

        this.FormLoader = new Tools.ElementLoader({
            Types: [Enums.elementType.Form]
        });

        this.ObjectTemplateLoader = new Tools.ElementLoader({
            Types: [Enums.elementType.ObjectTemplate],
            WithPrototypes: true,
            WithDescendants: true
        });

        this.CheckpointGroupTemplateLoader = new Tools.ElementLoader({
            Types: [Enums.elementType.CheckpointGroupTemplate],
            WithPrototypes: true,
            WithDescendants: true
        });

        this.MasterdataTemplateLoader = new Tools.ElementLoader({
            Types: [Enums.elementType.MasterdataTemplate],
            WithPrototypes: true,
            WithDescendants: true
        });

        this.SchedulingLoader = new Tools.SchedulingLoader();
    };

    /**
     * Setzt die Daten sämtlicher Loader zurück.
     */
    DataManager.prototype.Reset = function () {
        this.OrganizationUnitLoader.Reset();
        this.FormLoader.Reset();
        this.ObjectTemplateLoader.Reset();
        this.CheckpointGroupTemplateLoader.Reset();
        this.MasterdataTemplateLoader.Reset();
        this.SchedulingLoader.Reset();
    };

    global.DataManager = new DataManager();
})(window.Tools || (window.Tools = {}));