(function (global) {
    var Enums = {};

    Enums.LicensingModel = {
        awenko360: 0,
        awenkoSTART: 1,
        awenkoCHECKIN: 2
    };

    Enums.LicenseType = {
        Limited: 0,
        Full: 1,
        ViewRight: 2
    };

    Enums.elementType = {
        Root: 0,
        Location: 1,
        ObjectTemplate: 2,
        Form: 3,
        CheckpointGroupTemplate: 4,
        MasterdataTemplate: 5,
        PropertyTemplate: 6,
        FormTemplate: 7,
        Sensor: 10,
        CashRegister: 11,
        SingletonFormRow: 93,
        MasterdataGroup: 94,
        FormHeader: 95,
        FormFooter: 96,
        SubsampleFormRow: 97,
        Parametergroup: 99,
        AllGroups: [93, 94, 95, 96, 97, 99],
        Checkbox: 100,
        Number: 101,
        Line: 102,
        Memo: 103,
        Date: 104,
        Time: 105,
        Photo: 106,
        Scancode: 107,
        LocationCode: 108,
        ListBox: 109,
        MultiListBox: 110,
        Info: 111,
        Signature: 112,
        UsersAndTeams: 113,
        IndividualData: 114,
        PhoneNumber: 115,
        EMailAddresses: 116,
        Files: 117,
        AllowedItemsForAdHocParameters: [0, 1, 2, 4, 5],
        Print: 130
    };

    Enums.IndividualdataSchemaPropertyType = {
        Bool: 0,
        Number: 1,
        Text: 2,
        Date: 3,
        Time: 4,
        Image: 5,
        File: 6,
        Memo: 7,
        RichText: 8
    };

    Enums.FileType = {
        File: 0,
        Link: 1,
        YoutubeVideo: 2,
        VimeoPrivateVideo: 3,
        VimeoPublicVideo: 4
    };

    Enums.additionalPropertyType = {
        Group: 1,
        Checkbox: 2,
        Number: 3,
        Text: 4,
        Date: 5,
        Time: 6,
        Image: 7,
        Info: 8,
        PhoneNumber: 9,
        IndividualData: 10
    };

    Enums.propertyType = {
        Status: 1,
        Classification: 2,
        Priority: 3,
        Keyword: 4,
        ValueCategory: 5,
        Unit: 6
    };

    Enums.QueryType = {
        SCHEDULING: 0,
        FORM: 1,
        ADHOC: 2,
        ISSUES: 3,
        TUEVAUDITINVOICE: 4,
        OUMasterDataAndProperties: 5
    };

    Enums.PeriodType = {
        MANUALLY: 0,
        MANUALLYSHIFTS: 1,
        UNLIMITED: 2,
        TODAY: 3,
        YESTERDAY: 4,
        CURRENTWEEK: 5,
        CURRENTMONTH: 6,
        CURRENTYEAR: 7
    };

    Enums.FormReportType = {
        STANDARD: 0,
        WEIGHTED: 1,
        IFS: 2,
        WEIGHTEDFRANCHISE: 3,
        SURVEY: 4,
        QKZ: 5,
        TUEVAudit: 6,
        TUEVFoodservice: 7,
        TUEVSamples: 8,
        TrendAnalysis: 9,
        TUEVDirectContactTest: 10
    };

    Enums.issueType = {
        /**
         * Einzelaufgabe
         */
        Incident: 1,
        /**
         * Geplanter Rundgang
         */
        Resubmission: 2,
        /**
         * Formular
         */
        Form: 3,
        /**
         * Notiz
         */
        Note: 4,
        /**
         * Störmeldung
         */
        Disturbance: 5,
        /**
         * Begehung
         */
        Inspection: 6,
        /**
         * Umfrage
         */
        Survey: 7,
        /**
         * Externer Auftrag
         */
        Investigation: 8,
        /**
         * Abgeschlossene Störungen
         */
        DisturbancesCompleted: 9
    };

    Enums.elementActionType = {
        Notification: 0,
        Form: 1,
        Measure: 2,
        Comment: 3,
        OverdueNotification: 4,
        Photo: 5,
        ModifyParentIssue: 6
    };

    Enums.elementActionIndicator = {
        Always: 0,
        OnCategoryChanged: 1,
        OnValueChanged: 2
    };

    Enums.elementRequirementsType = {
        Any: 1,
        All: 2,
        None: 3
    };

    Enums.recorditemType = {
        Normal: 1,
        Deviation: 2,
        Unrecorded: 3,
        Calculated: 4,
        CalculatedDeviation: 5
    };

    Enums.commentType = {
        IssueComment: 0,
        IssueChangeComment: 1,
        RecorditemComment: 2,
        RecorditemChangeComment: 3
    };

    Enums.disturbanceType = {
        Issue: 1,
        Deviation: 2,
        Unrecorded: 3
    };

    Enums.AnalysisMode = {
        QUERYLIST: 'query-list',
        QUERYSETTINGS: 'query-settings',
        SUMMARY: 'summary',
        MATRIX: 'matrix',
        CHART: 'chart',
        WEIGHTEDISSUESELECTION: 'weighted-issue-selection',
        WEIGHTEDISSUERATING: 'weighted-issue-rating',
        WEIGHTEDISSUECORRECTIVEACTIONS: 'weighted-issue-corrective-actions',
        WEIGHTEDISSUECOMPARISON: 'weighted-issue-comparison',
        TUEVAUDITINVOICE: 'tuev-audit-invoice',
        OUMasterDataAndProperties: 'ou-masterdata-and-properties'
    };

    Enums.linking = {
        And: 0,
        Or: 1
    };

    Enums.operators = {
        Equal: { Value: 0, Text: '=' },
        Unequal: { Value: 1, Text: '&ne;' },
        Less: { Value: 2, Text: '&lt;' },
        LessOrEqual: { Value: 3, Text: '&le;' },
        Greater: { Value: 4, Text: '&gt;' },
        GreaterOrEqual: { Value: 5, Text: '&ge;' }
    };

    Enums.CorrectiveActionsGrouping = {
        None: 0,
        Rating: 1,
        Responsibility: 2,
        State: 3,
        Accountability: 4,
        Consultability: 5,
        Informed: 6
    };

    Enums.PrintOptionsGroups = {
        CoveringPage: 0,
        RecordData: 1,
        Formatting: 2,
        WeightedForms: 3,
        CorrectiveActions: 4
    };

    Enums.DatePickerTypes = {
        Creation: 0,
        Modification: 1,
        Deadline: 2
    };

    Enums.KeyCodes = {
        Backspace: 8,
        Tab: 9,
        Enter: 13,
        Shift: 16,
        Ctrl: 17,
        Alt: 18,
        Pause: 19,
        CapsLock: 20,
        Escape: 27,
        Space: 32,
        PageUp: 33,
        PageDown: 34,
        End: 35,
        Home: 36,
        Left: 37,
        Up: 38,
        Right: 39,
        Down: 40,
        LeftWindowKey: 91,
        RightWindowKey: 92,
        SelectKey: 93,
        F1: 112,
        F2: 113,
        F3: 114,
        F4: 115,
        F5: 116,
        F6: 117,
        F7: 118,
        F8: 119,
        F9: 120,
        F10: 121,
        F11: 122,
        F12: 123,
        NumLock: 144,
        ScrollLock: 145,
        Semicolon: 186,
        Comma: 188,
        A: 65,
        C: 67
    };

    Enums.SearchTypes = {
        Searchfilter: 'Searchfilter',
        Issues: 'Issues',
        CreatedBy: 'CreatedBy',
        ModifiedBy: 'ModifiedBy',
        Title: 'Title',
        Description: 'Description',
        Ident: 'Ident',
        CustomIdent: 'CustomIdent',
        ElementCodings: 'ElementCodings',
        IssueTypes: 'IssueTypes',
        Tasks: 'Tasks',
        Forms: 'Forms',
        Resubmissions: 'Resubmissions',
        Notes: 'Notes',
        Disturbances: 'Disturbances',
        Inspections: 'Inspections',
        Comments: 'Comments',
        Investigations: 'Investigation',
        Cached: 'Cached',
        Cached_Title: 'Cached_Title',
        Cached_Descriptions: 'Cached_Descriptions',
        Cached_ElementCodings: 'Cached_ElementDescriptions',
        CachedTypes: 'CachedTypes',
        Rooms: 'Rooms',
        Analysis: 'Analysis',
        Changemode: 'Changemode',
        Changemode_Rooms: 'Changemode_Rooms',
        Changemode_Resubmissions: 'Changemode_Resubmissions',
        Changemode_Items: 'Changemode_Items',
        Changemode_Groups: 'Changemode_Groups',
        Changemode_Forms: 'Changemode_Forms',
        Changemode_FormTemplates: 'Changemode_FormTemplates',
        Changemode_Files: 'Changemode_Files',
        Changemode_System: 'System',
        Changemode_Schedules: 'Changemode_Schedules',
        Changemode_Priorities: 'Changemode_Priorities',
        Changemode_Categories: 'Changemode_Categories',
        Changemode_Classifications: 'Changemode_Classifications',
        Changemode_Keywords: 'Changemode_Keywords',
        Changemode_Status: 'Changemode_Status',
        ExactIdent: 'ExactIdent',
        Recorditems: 'Recorditems'
    };

    Enums.IssueProcessingStatus = {
        OK: 0,
        Warning: 1,
        Overdue: 2,
    };

    /* bitwise options x^2 */
    Enums.FrequencySubdayTypes = {
        OnceADay: 1,
        RepeatedMinutely: 4,
        RepeatedHourly: 8,
        RepeatOnStateChange: 16
    };

    /* bitwise options x^2 */
    Enums.FrequencyTypes = {
        Daily: 4,
        Weekly: 8,
        Monthly: 16,
        MonthlyRelative: 32,
        Yearly: 64,
        YearlyRelative: 128
    };

    Enums.Weekdays = {
        Sunday: 1,
        Monday: 2,
        Tuesday: 3,
        Wednesday: 4,
        Thursday: 5,
        Friday: 6,
        Saturday: 7
    };

    Enums.Months = {
        January: 1,
        February: 2,
        March: 3,
        April: 4,
        May: 5,
        June: 6,
        July: 7,
        August: 8,
        September: 9,
        October: 10,
        November: 11,
        December: 12
    };

    Enums.PeriodTarget = {
        Recording: 0,
        IssueCreation: 1,
        IssueModification: 2,
        IssueDeadline: 3
    };

    Enums.FontSizeType = {
        Small: 0,
        Medium: 1,
        Large: 2
    };

    Enums.Orientation = {
        Automatic: 0,
        Potrait: 1,
        Landscape: 2
    };

    Enums.CustomMenuItemActionType = {
        ShowCalendar: 0,
        ShowIssueReport: 1,
        ShowCheckpoints: 2,
        OpenSchedulingWindow: 3,
        OpenFormWindow: 4,
        ShowInformation: 5,
        ShowFloorPlan: 6,
        ShowBluetoothSettings: 7,
        Link: 8,
        ShowIndividualData: 9,
        ShowScaleSettings: 10,
        ShowDisturbancesFromRecordings: 11,
        ShowSchedulingOverview: 12,
        ShowRecordedFiles: 13,
        ShowNews: 14,
        ShowDashboardOffice: 15,
        ShowIssueArchive: 16
    };

    Enums.CustomMenuItemSystemID = {
        DashboardOffice: 'DashboardOffice',
        Information: 'Information',
        Parameters: 'Parameters',
        IssueReport: 'IssueReport',
        DisturbancesFromRecordings: 'DisturbancesFromRecordings',
        IssueArchive: 'IssueArchive',
        SchedulingOverview: 'SchedulingOverview',
        RecordedFiles: 'RecordedFilesOverview',
        News: 'News'
    };

    Enums.ImageSize = {
        Small: 0,
        Normal:  1,
        Large: 2
    };

    Enums.TemporaryDeviationOffsetType = {
        Minute: 0,
        Hour: 1,
        Day: 2
    };

    Enums.HttpActionType = {
        Read: 'read',
        Write: 'write',
        Delete: 'delete',
        SendMails: 'send-mails'
    };

    Enums.ModificationType = {
        NotModified: 0,
        Added: 1,
        Edited: 2,
        Deleted: 3,
        Moved: 4,
        EditedAndMoved: 5
    };

    Enums.StatusState = {
        All: 0,
        Open: 1,
        Closed: 2
    };

    Enums.HttpStatusCode = {
        Accepted: 202,
        Bad_Gateway: 502,
        Bad_Request: 400,
        Conflict: 409,
        Continue: 100,
        Created: 201,
        Expectation_Failed: 417,
        Failed_Dependency: 424,
        Forbidden: 403,
        Gateway_Timeout: 504,
        Gone: 410,
        Http_Version_Not_Supported: 505,
        Insufficient_Space_On_Resource: 419,
        Insufficient_Storage: 507,
        Internal_Server_Error: 500,
        Length_Required: 411,
        Locked: 423,
        Method_Failure: 420,
        Method_Not_Allowed: 405,
        Moved_Permanently: 301,
        Moved_Temporarily: 302,
        Multi_Status: 207,
        Multiple_Choices: 300,
        Network_Authentication_Required: 511,
        No_Content: 204,
        Non_Authoritative_Information: 203,
        Not_Acceptable: 406,
        Not_Found: 404,
        Not_Implemented: 501,
        Not_Modified: 304,
        Ok: 200,
        Partial_Content: 206,
        Payment_Required: 402,
        Precondition_Failed: 412,
        Precondition_Required: 428,
        Processing: 102,
        Proxy_Authentication_Required: 407,
        Request_Header_Fields_Too_Large: 431,
        Request_Timeout: 408,
        Request_Too_Long: 413,
        Request_Uri_Too_Long: 414,
        Requested_Range_Not_Satisfiable: 416,
        Reset_Content: 205,
        See_Other: 303,
        Service_Unavailable: 503,
        Switching_Protocols: 101,
        Temporary_Redirect: 307,
        Too_Many_Requests: 429,
        Unauthorized: 401,
        Unprocessable_Entity: 422,
        Unsupported_Media_Type: 415,
        Use_Proxy: 305
    }

    Enums.processType = {
        Custom: 0,
        Created: 1,
        Edited: 2
    };

    Enums.processActionType = {
        Email: 0,
        PDF: 1,
        Responsibilities: 2,
        ShareCorrectiveActions: 3
    };

    Enums.notificationType = {
        IssueCreated: 1,
        IssueModified: 2,
        IssueCommented: 3,
        IssueReminder: 4,
        IssueArchived: 5,
        IssueExpired: 6,
        RecorditemCreated: 7,
        FormIssueModified: 8,
        RecorditemOverdue: 9,
        FormIssueArchived: 10
    };

    Enums.UserImport = {
        Errors: {
            AlreadyExist: 'already exists',
            AlreadyExistsInFile: 'already exists in file',
            DoesNotExist: 'does not exist',
            IsInvalid: 'is invalid',
            ContainsInvalidLetters: 'contains invalid letters',
            WrongLicenseCombination: 'wrong Role/User license combination',
            IsNotGiven: 'is not given',
            PasswordPolicyNotApplied: 'Password policy is not applied',
        },
        Username: 'Username',
        Password: 'Password',
        FirstName: 'FirstName',
        LastName: 'LastName',
        EmailAddress: 'EmailAddress',
        UserLicenseType: 'UserLicenseType',
        LicenseType: 'LicenseType',
        Role: 'Role',
        OrganisationUnit: 'OrganisationUnit'
    };

    Enums.ChangeCommentRequiredType = {
        No: 0,
        OnChange: 1,
        OnSet: 2,
        OnChangeOrSet: 3
    };

    Enums.StatusChangeTiming = {
        Offset: 0,
        Time: 1
    };

    Enums.AutomaticStatusChangeOffsetType = {
        Minutes: 0,
        Hours: 1,
        Days: 2,
        Months: 3,
        Years: 4
    };

    /**
     * Stellt Konstanten bereit, welche den ErrorCode bei einem Forbidden-Response repräsentieren.
     */
    Enums.ForbiddenResponseErrorCode = {
        /**
         * Gibt an, dass der Nutzer nicht über die benötigten Rechte verfügt.
         */
        InsufficientRights: 0,
        /**
         * Gibt an, dass der Typ der hochgeladenen Datei nicht erlaubt war.
         */
        FileType: 1,
        /**
         * Gibt an, dass der Request auf Grund fehlender Lizenzen nicht erlaubt ist.
         */
        MissingLicense: 2,
        /**
         * Gibt an, dass der Request auf Grund fehlendem Root-Element nicht erlaubt ist.
         */
        MissingRootElement: 3
    };

    /**
     * Stellt Konstanten für die vom System vorgebenen Rollenrechte bereit.
     */
    Enums.Rights = {
        /**
         * Stellt Rechte für die Durchführung von Erfassungen bereit.
         */
        RecordingRights: {
            /**
            * Daten adhoc erfassen
            */
            AdhocRecording: 'ADHOCERF',
            /**
            * Erfasste Werte ändern
            */
            ModifyRecordedValues: 'EBE',
            /**
            * Dateien an Erfassungen löschen
            */
            DeleteAdditionalRecorditemFiles: 'DRF',
            /**
            * Stammdaten bearbeiten
            */
            ModifyMasterdata: 'EMD',
            /**
            * Prüfgruppen entsperren
            */
            UnlockRecordings: 'ULR',
            /**
            * Erfassungen löschen
            */
            DeleteRecorditems: 'REC-DELETE'
        },
        /**
        * Stellt Rechte für die Kommentar-/Dokumentionsfunktion bereit.
        */
        DocumentationRights: {
            /**
            * Kommentare erstellen / bearbeiten / löschen
            */
            CreateAndModifyIssueComments: 'CMC',
            /**
            * Maßnahmen am Prüfpunkt erstellen / bearbeiten
            */
            CreateAndModifyActionIssue: 'CMC-ISSUE',
            /**
            * Dateien an Erfassungen erstellen / bearbeiten
            */
            CreateAndModifyAdditionalRecordingFiles: 'CMC-PHOTO',
            /**
            * Kommentare am Prüfpunkt erstellen / bearbeiten
            */
            CreateAndModifyRecordingComments: 'CMC-COMMENT',
            /**
            * Kommentare anderer Benutzer bearbeiten und löschen
            */
            ModifyOrDeleteCommentsOfOtherUsers: 'CMC-COMMENT-DELETE-OTHERS',
            /**
            * Dateien an Erfassungen löschen
            */
            DeleteAdditionalRecordingFiles: 'DRF'
        },
        /**
        * Stellt Rechte für den Aktuell-Modus im Office bereit.
        */
        CurrentModeRights: {
            /**
            * Auswertungen erstellen / bearbeiten / löschen
            */
            ReportQueryModifications: 'CMSQ',
            /**
            * Synchronisationsübersicht anzeigen
            */
            ShowSyncOverview: 'SSO',
            /**
            * Stammdaten exportieren
            */
            ExportMasterData: 'EXMD',
            /**
            * Alle Pläne anzeigen lassen
            */
            ShowAllSchedulings: 'SAS',
            /**
            * Darf alle Formulare sehen
            */
            CreateAnyForm: 'MCUF',
            /**
            * Darf seine Sitzungen verwalten
            */
            ManageSessions: 'MS'
        },
        /**
        * Stellt Rechte für den Änderungsmodus im Office bereit.
        */
        ModificationModeRights: {
            /**
            * Umschalten in den Änderungsmodus
            */
            AccessEditMode: 'AEM',
            /**
            * Berechtigungen setzen
            */
            ModifyPermissions: 'CMU'
        },
        /**
        * Stellt Rechte für die Bearbeitung von Vorgängen bereit.
        */
        IssueRights: {
            /**
            * Statuswechsel bei unvollständigen Erfassungen
            */
            ChangeStateOfIncompleteIssues: 'CET',
            /**
            * Alle Vorgänge anzeigen lassen
            */
            ShowAllIssues: 'SAI',
            /**
            * Selbst erstellte / bearbeitete Vorgänge anzeigen lassen
            */
            ShowSelfCreatedOrModifiedIssues: 'SCMI',
            /**
            * Vorgänge allen Benutzern und Teams zuweisen
            */
            AllowAssigningAllUsersToIssues: 'AAUAR',
            /**
            * Nicht erfasste Teilproben löschen
            */
            DeleteSubsamples: 'DSS',
            /**
            * Vorgänge erstellen / bearbeiten
            */
            Issues_CreateOrModifyIssues: 'TMFT',
            /**
            * Einzelaufgaben
            */
            Issues_CreateOrModifyTasks: 'TMFT-TASKS',
            /**
            * Formulare
            */
            Issues_CreateOrModifyForms: 'TMFT-FORMS',
            /**
            * Notizen
            */
            Issues_CreateOrModifyNotes: 'TMFT-NOTES',
            /**
            * Störmeldungen
            */
            Issues_CreateOrModifyDisturbances: 'TMFT-DISTURBANCES',
            /**
            * Begehungen
            */
            Issues_CreateOrModifyInspections: 'TMFT-INSPECTIONS',
            /**
            * Externe Aufträge
            */
            Issues_CreateOrModifyInvestigations: 'TMFT-INVESTIGATIONS',
            /**
            * Geplante Aufgaben
            */
            Issues_CreateOrModifySchedulings: 'TMFT-SCHEDULINGS',
            /**
            * In Störmeldung wandeln
            */
            Issues_ConvertTaskToDisturbance: 'TMFT-CONVERT-TO-DISTURBANCE',
            /**
            * In Aufgabe wandeln
            */
            Issues_ConvertIssueToTask: 'TMFT-CONVERT-TO-TASK',
            /**
            * Notiz aktivieren
            */
            IssueProperties_ActivateNote: 'TMFT-ACTIVATE',
            /**
            * Klassifizierungen bearbeiten
            */
            IssueProperties_Classifications: 'TMFT-CLASSIFICATIONS',
            /**
            * Kontakte bearbeiten
            */
            IssueProperties_Contacts: 'TMFT-CONTACTS',
            /**
            * Kontakte bearbeiten
            */
            IssueProperties_ContactGroups: 'TMFT-CONTACTGROUPS',
            /**
            * Indiv. ID bearbeiten
            */
            IssueProperties_CustomId: 'TMFT-CUSTOMID',
            /**
            * Notiz deaktivieren
            */
            IssueProperties_DeactivateNote: 'TMFT-DEACTIVATE',
            /**
            * Termin bearbeiten
            */
            IssueProperties_Deadline: 'TMFT-DEADLINE',
            /**
            * Beschreibung bearbeiten
            */
            IssueProperties_Description: 'TMFT-DESCRIPTION',
            /**
            * Aufwandsschätzung bearbeiten
            */
            IssueProperties_EstimatedEffort: 'TMFT-ESTIMATEDEFFORT',
            /**
            * Dateien bearbeiten
            */
            IssueProperties_Files: 'TMFT-FILES',
            /**
            * Schlagwörter bearbeiten
            */
            IssueProperties_Keywords: 'TMFT-KEYWORDS',
            /**
            * Organisationseinheit bearbeiten
            */
            IssueProperties_Location: 'TMFT-LOCATION',
            /**
            * Priorität bearbeiten
            */
            IssueProperties_Priority: 'TMFT-PRIORITY',
            /**
            * Erinnerung bearbeiten
            */
            IssueProperties_Reminding: 'TMFT-REMINDING',
            /**
            * Benutzer & Teams bearbeiten
            */
            IssueProperties_Responsibilities: 'TMFT-RESPONSIBILITIES',
            /**
            * Status bearbeiten
            */
            IssueProperties_State: 'TMFT-STATE',
            /**
            * Bezeichnung bearbeiten
            */
            IssueProperties_Title: 'TMFT-TITLE'
        }
    };

    Enums.Investigations = {
        /**
         * Gibt an, in welchem Zustand sich ein externer Auftrag aktuell befindet.
         */
        Status: {
            /**
             * Offen für die Bearbeitung
             */
            Open: 0,
            /**
             * Abgeschlossen, aber Daten noch nicht zurücksynchronisiert
             */
            Finished: 1,
            /**
             * Erfasste Daten wurden mit dem Mandanten synchronisiert
             */
            Pulled: 2,
            /**
             * Neuer Auftrag wurde noch nicht zum Trustservice synchronisiert
             */
            Unsynced: 3,
            /**
             * Ein Löschen des Auftrags wurde angefordert
             */
            CancellationRequested: 4,
            /**
             * Der Auftrag wurde vom Trustservice entfernt
             */
            Cancelled: 5,
            /**
             * Bei der Übertragung des Auftrags trat ein Fehler auf
             */
            Error: 6
        },
        /**
         * Treten Fehler bei der Synchronisation auf, lassen diese sich hierüber näher bestimmen.
         */
        Error: {
            /**
             * Die am Formular hinterlegte Eigenschaft für die Bestimmung der E-Mail-Empfänger fehlt
             */
            MissingAdditionalProperty: 0,
            /**
             * Dem externen Auftrag wurde kein Plan zugeordnet
             */
            MissingScheduling: 1,
            /**
             * Es wurden keine Empfänger für den externen Auftrag festgelegt
             */
            NoRecipients: 2,
            /**
             * Beim Hochladen des externen Auftrags kam es zu einem Fehler
             */
            UploadError: 3,
            /**
             * Beim Herunterladen des externen Auftrags kam es zu einem Fehler
             */
            DownloadError: 4,
            /**
             * In der Konfiguration des externen Auftrags wurde kein E-Mail-Template angegeben
             */
            NoMailTemplateConfigured: 5
        }
    };

    Enums.UpdateNotificationAction = {
        MarkAsRead: 0,
        MarkAllAsRead: 1
    };

    Enums.CenterTreeSearchResultsRepresentation = {
        Tree: 'tree',
        ResultsOnlyList: 'results-only-list'
    };

    return (global.Enums = Enums);
})(window);