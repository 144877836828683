(function (global) {
    var Tools = {};

    /**
     * Prüft, ob der übergebene CSRF-Token noch gültig ist
     * @param csrfToken - Der Token der geprüft werden soll
     * @returns {boolean} - Ist der Token noch gültig
     */
    Tools.IsCsrfTokenValid = function(csrfToken) {
        var tokenCreationTimestamp = parseInt(csrfToken.split('.').pop() || '0', 10);

        if (isNaN(tokenCreationTimestamp)) {
            return false;
        }

        // Erstellzeitpunkt des Tokens + Gültigkeit des Tokens (1/4 wird als Puffer abgezogen)
        return Date.now() < (tokenCreationTimestamp + (Config.CookieConfig.CsrfValidityMinutes * 45)) * 1000;
    };

    /**
     * Erstellt ein Deferred bei dem das Rückgabe-Objekt der promise Funktion um eine abort Funktion erweitert wird
     * @param abortCallback - Der Callback, welcher nach dem Aufruf von abort aufgerufen werden soll
     * @returns {JQuery.Deferred<any, any, any>}
     */
    Tools.CreateAbortableDeferred = function (abortCallback) {
        var deferred = $.Deferred();
        var _promise = deferred.promise;

        deferred.promise = function() {
            var prom = _promise.apply(this, arguments);

            prom.abort = function() {
                if (deferred.state() === 'pending') {
                    abortCallback();
                    deferred.reject();
                }

                return this;
            };

            return prom;
        };

        return deferred;
    };

    Tools.IsValidGuid = function (str) {
        return (/[A-F0-9]{8}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{4}-[A-F0-9]{12}/ig).test(str);
    };

    Tools.GetEmptyGuid = function () {
        return '00000000-0000-0000-0000-000000000000';
    };

    Tools.GetDefaultColors = function () {
        return ['#7E1210', '#DB843D', '#F7EF38', '#B5CA92', '#89A54E', '#80805C', '#92A8CD', '#3D96AE', '#385B6B', '#80699B', '#FFFFFF', '#000000'];
    };

    Tools.FixSVGMetrics = function (marksSVG) {
        if (!marksSVG) {
            return null;
        }

        var reg = /<svg[^>]*width="(\d+)"[^>]*height="(\d+)"[^>]*>/ig;

        return marksSVG.replace(reg, '<svg class="marks" height="0%" viewBox="0 0 $1 $2" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns="http://www.w3.org/2000/svg">');
    };

    Tools.FixMarksSize = function (img) {
        if (img.style.display === 'none') {
            setTimeout(function()  {
                Tools.FixMarksSize(img);
            }, 300);

            return;
        }

        var $img = $(img);
        var $marks = $img.siblings('svg.marks');

        if (!$marks.length) {
            $marks = $img.closest('.image-container').find('svg.marks');
        }

        if ($marks.length) {
            $marks.css({
                'width': img.clientWidth,
                'height': img.clientHeight
            });
        }
    };

    Tools.createUnitTitle = function (unit) {
        if (!unit) {
            return '';
        }

        var unitTitle = unit.Title;

        if (!!unit.Description) {
            unitTitle += ' (' + unit.Description + ')';
        }

        return unitTitle;
    };

    Tools.replaceHtmlEntities = function (val) {
        if (typeof val !== 'string') {
            return val;
        }

        return val
            .replace(/<br\s*\/?>/gim, '\n')
            .replace(/&nbsp;/gim, ' ');
    };

    Tools.escRegExp = function (str) {
        return (str || '').replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, '\\$&');
    };

    Tools.escapeHtml = function (str) {
        if (typeof str !== 'string') {
            return str;
        }

        str = str
            .replace(/</gm, '&lt;')
            .replace(/>/gm, '&gt;')
            .replace(/"/gm, '&quot;')
            .replace(/'/gm, '&#039;');

        return str.replace(/\r?\n|\r/g, '<br />');
    };

    Tools.unescapeHtml = function (str) {
        if (typeof str !== 'string') {
            return str;
        }

        return (str || '')
            .replace(/&lt;/gm, '<')
            .replace(/&gt;/gm, '>')
            .replace(/&quot;/gm, '"')
            .replace(/&#039;/gm, '\'')
            .replace(/<br( \/)?>/gm, '\n')
    };

    Tools.SortByOrder = function (objA, objB) {
        return (objA.Order || 0) - (objB.Order || 0);
    };

    Tools.SortByPosition = function (objA, objB) {
        return (objA.Position || 0) - (objB.Position || 0);
    };

    Tools.SortAssignedFilesFromCatalogue = function (a, b, dataSourceIsReportMode) {
        dataSourceIsReportMode = dataSourceIsReportMode || false;

        var posA = a.Position || 0;
        var posB = b.Position || 0;

        if (posA === posB) {
            var fileA = dataSourceIsReportMode ? Files[a.OID] : changemode.Files[a.OID];
            var fileB = dataSourceIsReportMode ? Files[b.OID] : changemode.Files[b.OID];

            if (!(fileA && fileB)) {
                return 0;
            }

            return Tools.SortByTitle(fileA, fileB);
        }

        return posA - posB;
    };

    Tools.SortByRow = function (objA, objB) {
        if (!objA) {
            return -1;
        }

        if (!objB) {
            return 1;
        }

        return (objA.Row || 0) - (objB.Row || 0);
    };

    Tools.SortByLastnameThenFirstname = function (objA, objB) {
        if (!objA) {
            return -1;
        }

        if (!objB) {
            return 1;
        }

        var lastnameA = (objA.Lastname || '').toLowerCase();
        var lastnameB = (objB.Lastname || '').toLowerCase();

        if (lastnameA === lastnameB) {
            var firstnameA = (objA.Firstname || '').toLowerCase();
            var firstnameB = (objB.Firstname || '').toLowerCase();

            return  firstnameA === firstnameB ? 0 : (firstnameA < firstnameB ? -1 : 1);
        }
        return lastnameA < lastnameB ? -1 : 1;
    };

    Tools.SortByFirstnameThenLastname = function (objA, objB) {
        if (!objA) {
            return -1;
        }

        if (!objB) {
            return 1;
        }

        var firstnameA = (objA.Firstname || '').toLowerCase();
        var firstnameB = (objB.Firstname || '').toLowerCase();

        if (firstnameA === firstnameB ) {
            var lastnameA = (objA.Lastname || '').toLowerCase();
            var lastnameB = (objB.Lastname || '').toLowerCase();

            return lastnameA === lastnameB ? 0 : (lastnameA < lastnameB ? -1 : 1);
        }
        return firstnameA < firstnameB  ? -1 : 1;
    };

    Tools.SortByFullname = function (a, b) {
        if (!a) {
            return -1;
        }

        if (!b) {
            return 1;
        }

        var fullnameA = (a.Fullname || '').toLowerCase();
        var fullnameB = (b.Fullname || '').toLowerCase();

        if (fullnameA === fullnameB) {
            return 0;
        }

        return fullnameA > fullnameB ? 1 : -1;
    };

    Tools.SortByTitle = function (a, b) {
        if (!a) {
            return -1;
        }

        if (!b) {
            return 1;
        }

        return a.Title.localeCompare(b.Title, undefined, { sensitivity: 'accent' });
    };

    Tools.SortByCategoryName = function(a, b) {
        if (!a) {
            return -1;
        }

        if (!b) {
            return 1;
        }

        var nameA = a.name.toLowerCase();
        var nameB = b.name.toLowerCase();

        if (nameA === nameB) {
            return 0;
        }

        return nameA > nameB ? -1 : 1;
    };

    Tools.SortStringArray = function (a, b) {
        return (a || '').localeCompare(b || '', undefined, { sensitivity: 'accent' });
    };

    Tools.createOverlay = function (id, zIndex, fn) {
        var $overlay = $('<div id="{0}" class="overflow"></div>'.format(id));

        if (+zIndex) {
            $overlay.css('z-index', zIndex);
        }

        if (fn && fn instanceof Function) {
            $overlay.on('click', fn);
        }

        $('body').append($overlay);

        return $overlay;
    };

    Tools.getFirstSortedElement = function (entityMap, propertyName, isChangemode) {
        var entities = Object
          .keys(entityMap)
          .map(function (k) { return entityMap[k]; })
          .sort(function (a, b) {
            var propA = (a[propertyName] || '');
            var propB = (b[propertyName] || '');

            if (typeof propA === 'string') {
                propA = propA.toLowerCase();
            }

            if (typeof propB === 'string') {
              propB = propB.toLowerCase();
            }

            if (propA === propB) {
                return 0;
            }

            return propA < propB ? -1 : 1;
        });

        for (var i = 0, len = entities.length; i < len; i++) {
            var entity = entities[i];

            if (!isChangemode || entity.ModificationType !== Enums.ModificationType.Deleted) {
                return entity;
            }
        }

        return null;
    };

    Tools.partial = function (fn) {
        var slice = Array.prototype.slice;
        var args = slice.call(arguments, 1);

        return function () {
            return fn.apply(this, args.concat(slice.call(arguments)));
        };
    };

    Tools.indexOf = function (collection, valueOrFunction, key) {
        if (typeof valueOrFunction === 'function') {
            for (var i = 0, len = (collection || []).length; i < len; i++) {
                if (valueOrFunction(collection[i]) === true) {
                    return i;
                }
            }
        } else if (typeof key === 'string') {
            for (var i = 0, len = (collection || []).length; i < len; i++) {
                if (collection[i].hasOwnProperty(key) &&
                        collection[i][key] === valueOrFunction) {
                    return i;
                }
            }
        } else {
            for (var i = 0, len = (collection || []).length; i < len; i++) {
                if (collection[i] === valueOrFunction) {
                    return i;
                }
            }
        }

        return -1;
    };

    Tools.contains = function (collection, valueOrFunction, key) {
        return !!~Tools.indexOf(collection, valueOrFunction, key);
    };

    Tools.any = function (collection, value, key) {
        for (var i = 0, len = (value || []).length; i < len; i++) {
            if (Tools.contains(collection, value[i], key)) {
                return true;
            }
        }

        return false;
    };

    Tools.getFirst = function (collection, valueOrFunction, key) {
        var idx = Tools.indexOf(collection, valueOrFunction, key);

        if (!!~idx) {
            return collection[idx];
        }

        return null;
    };

    Tools.remove = function (collection, valueOrFunction, key) {
        collection = collection || [];

        var idx = Tools.indexOf(collection, valueOrFunction, key);

        if (!!~idx) {
            collection.splice(idx, 1);
        }

        return collection;
    };

    Tools.addUnique = function (collection, value, key) {
        collection = collection || [];

        if (typeof value === 'undefined' || value === null) {
            return collection;
        }

        if (!$.isArray(value)) {
            value = [value];
        }

        var useKey = typeof key === 'string';

        for (var idx = 0; idx < value.length; idx++) {
            var val = value[idx];

            if (useKey) {
                if (val.hasOwnProperty(key) &&
                    !Tools.contains(collection, val[key], key)) {
                    collection.push(val);
                }
            } else if (!Tools.contains(collection, val)) {
                collection.push(val);
            }
        }

        return collection;
    };

    Tools.valueOrDefault = function (value, defaultValue) {
        if (typeof value === 'undefined' || value === null) {
            return defaultValue;
        }

        return value;
    };

    Tools.reduce = function (collection, fn, acc) {
        var len = (collection || []).length;
        var i = 0;

        if (len && (typeof acc === 'undefined' || acc === null)) {
            acc = collection[0];
            i = 1;
        }

        while (i < len) {
            acc = fn(collection[i], acc);
            i++;
        }

        return acc;
    };

    Tools.all = function (dict, attr, op, value) {
        var propCount = Object.keys(dict || {}).length;
        var result = [];

        if (propCount) {
            for (var key in dict) {
                var obj = dict[key];
                var invValue = obj[attr];
                var isHit = false;

                switch (op) {
                    case '!==':
                        isHit = invValue !== value;
                        break;
                    case '===':
                        isHit = invValue === value;
                        break;
                    case '<':
                        isHit = invValue < value;
                        break;
                    case '<=':
                        isHit = invValue <= value;
                        break;
                    case '>':
                        isHit = invValue > value;
                        break;
                    case '>=':
                        isHit = invValue >= value;
                        break;
                }

                if (isHit) {
                    result.push(obj);
                }
            }
        }

        return result;
    };

    Tools.getAll = function (collection, valueOrFunction, key) {
        var result = [];
        var compareFunction;

        if (typeof valueOrFunction === 'function') {
            compareFunction = function(item) {
                return valueOrFunction(item) === true;
            };
        } else if (typeof key === 'string') {
            compareFunction = function(item) {
                return Tools.IsSet(item) && item.hasOwnProperty(key) && item[key] === valueOrFunction;
            };
        } else {
            compareFunction = function(item) {
                return item === valueOrFunction;
            }
        }

        if (collection instanceof Array) {
            for (var i = 0, len = (collection || []).length; i < len; i++) {
                var collectionItem = collection[i];

                if (compareFunction(collectionItem)) {
                    result.push(collectionItem);
                }
            }
        } else if (Tools.hasProperties(collection)) {
            for (var itemKey in collection) {
                if (!collection.hasOwnProperty(itemKey)) {
                    continue;
                }

                var collectionItem = collection[itemKey];

                if (compareFunction(collectionItem)) {
                    result.push(collectionItem);
                }
            }
        }

        return result;
    };

    Tools.clone = function (value, excludes) {
        function internalCloneWithExcludes(value, excludes) {
            if ($.isArray(value)) {
                return $.map(value, function (v) {
                    return internalCloneWithExcludes(v, excludes);
                });
            }

            if ($.isPlainObject(value)) {
                var result = {};

                $.each(value, function (k, v) {
                    if (!Tools.contains(excludes, k)) {
                        result[k] = internalCloneWithExcludes(v, excludes);
                    }
                });

                return result;
            }

            if (value instanceof Date) {
                return new Date(value.getTime());
            }

            return value;
        }

        function internalCloneWithoutExcludes(value) {
            if ($.isArray(value)) {
                return $.map(value, function (v) {
                    return internalCloneWithoutExcludes(v);
                });
            }

            if ($.isPlainObject(value)) {
                var result = {};

                $.each(value, function (k, v) {
                    result[k] = internalCloneWithoutExcludes(v);
                });

                return result;
            }

            if (value instanceof Date) {
                return new Date(value.getTime());
            }

            return value;
        }

        return (excludes || []).length ?
                internalCloneWithExcludes(value, excludes) :
                internalCloneWithoutExcludes(value);
    };

    Tools.sort = function (collection, key) {
        return collection.sort(function (a, b) {
            var valA = a[key];
            var valB = b[key];

            if (valA === valB) {
                return 0;
            }

            return valA > valB ? 1 : -1;
        });
    };

    Tools.pushStateToHistory = function (fragment) {
        history.pushState(null, navigator.title, fragment);
        $(window).trigger('hashchange');
    };

    Tools.GetFileByFilename = function (filename) {
        if (Object.keys(Files || {}).length) {
            for (var oid in Files) {
                var file = Files[oid];

                if (file.Filename === filename) {
                    return file;
                }
            }
        }
    };

    Tools.isNumber = function (value) {
        return typeof value === 'number' && !isNaN(value);
    };

    Tools.isBool = function (value) {
        return typeof value === 'boolean';
    };

    Tools.IsElementInViewport = function (element, ignoreXAxis, ignoreYAxis) {
        if (!element) {
            return false;
        }

        if (typeof jQuery === "function" && element instanceof jQuery) {
            element = element[0];
        }

        var rect = element.getBoundingClientRect();

        return (ignoreYAxis || (rect.top >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))) &&
            (ignoreXAxis || (rect.left >= 0 && rect.right <= (window.innerWidth || document.documentElement.clientWidth)));
    };

    Tools.Throttle = function (fn, threshold, scope) {
        var last;
        var timeout;

        threshold = threshold || 250;

        return function () {
            var context = scope || this;
            var now = +new Date();
            var args = arguments;

            if (last && now < last + threshold) {
                clearTimeout(timeout);
                timeout = setTimeout(function () {
                    last = now;
                    fn.apply(context, args);
                }, threshold);
            } else {
                last = now;
                fn.apply(context, args);
            }
        };
    };

    Tools.Debounce = function (fn, threshold, immediate, scope) {
        var timeout;

        threshold = threshold || 250;

        return function() {
            var context = scope || this;
            var args = arguments;

            if (immediate && !timeout) {
                fn.apply(context, args);
            }

            clearTimeout(timeout);
            timeout = setTimeout(function() {
                timeout = null;

                if (!immediate) {
                    fn.apply(context, args);
                }
            }, threshold);
        };
    };

    Tools.GetDefaultUIRights = function (targetIsOffice) {
        targetIsOffice = targetIsOffice || false;

        var menus = [];

        CustomMenuItems.GetMenuIds().forEach(function (id) {
            var menuItems = CustomMenuItems.GetByMenuId(id);
            var children = [];

            menuItems
                .filter(function (menuItem) {
                    return !menuItem.Deleted && targetIsOffice ?
                        menuItem.IsAvailableInOffice :
                        menuItem.IsAvailableInRecordingApp;
                })
                .forEach(function (menuItem) {
                    children.push({
                        ID: menuItem.ID,
                        IsEnabled: true,
                        Position: menuItem.Position
                    });
                });

            children.sort(function(a, b) {
                return a.Position - b.Position;
            });

            if (!children.length) {
                return;
            }

            menus.push({
                ID: id,
                Children: children
            });
        });

        return menus;
    };

    Tools.compareNaturalStrings = function (a, b) {
        var lenA, lenB, i, j, strA, strB, chunkA, chunkB, result;

        if (!a && !b) {
            return 0;
        }

        a = a || '';
        b = b || '';

        lenA = a.length;
        lenB = b.length;
        i = 0;
        j = 0;

        while (i < lenA && j < lenB) {
            var charA = a[i];
            var charB = b[j];
            var spaceA = new Array(lenA);
            var spaceB = new Array(lenB);
            var locA = 0;
            var locB = 0;

            do {
                spaceA[locA++] = charA;
                i++;

                if (i < lenA) {
                    charA = a[i];
                } else {
                    break;
                }
            } while (/\d/.test(charA) === /\d/.test(spaceA[0]));

            do {
                spaceB[locB++] = charB;
                j++;

                if (j < lenB) {
                    charB = b[j];
                } else {
                    break;
                }
            } while (/\d/.test(charB) === /\d/.test(spaceB[0]));

            strA = spaceA.join('');
            strB = spaceB.join('');

            if (/\d/.test(spaceA[0]) && /\d/.test(spaceB[0])) {
                chunkA = parseInt(strA, 10);
                chunkB = parseInt(strB, 10);

                result = chunkA - chunkB;
            } else if (strA === strB) {
                result = 0;
            } else if (strA > strB) {
                result = 1;
            } else {
                result = -1;
            }

            if (result !== 0) {
                return result;
            }
        }

        return lenA - lenB;
    };

    Tools.hasProperties = function(obj) {
        if (!obj) {
            return false;
        }

        for (var key in obj) {
            if (obj.hasOwnProperty(key)) {
                return true;
            }
        }

        return false;
    };

    Tools.createTreeContextMenu = function ($tree) {
        if (!$tree || !($tree instanceof $) || !$tree.is(':tree')) {
            return;
        }

        var tree = $tree.tree(true);

        function getTree() {
            if (tree.allNodes == null) {
                return $tree.tree(true);
            }

            return tree;
        }

        $tree.ContextMenu({
          selector: '.jquery-tree-node',
          zIndex: $tree.css('z-index'),
          show: function (node) {
              tree = getTree();

              if (!tree || !tree.allNodes) {
                  return false;
              }

              tree.removeClassFromAllNodes('jquery-tree-node-context-menu-selected');
              tree.addClassToNode(node, 'jquery-tree-node-context-menu-selected');
          },
          hide: function () {
              tree = getTree();

              if (!tree || !tree.allNodes) {
                  return false;
              }

              tree.removeClassFromAllNodes('jquery-tree-node-context-menu-selected');
          },
          items: [
              {
                  type: 1,
                  title: function() {
                      tree = getTree();

                      if (!tree || !tree.allNodes) {
                          return;
                      }

                      var elementOID = contextmenu.$element.data('id');
                      var treeNode = tree.allNodes[elementOID];
                      var allChecked = true;
                      var disabledCheckboxesExist = false;

                      tree.walk(treeNode, function (node) {
                          var isDisabled = node.checkbox &&
                              (
                                  node.checkbox.disabled === true ||
                                  node.checkbox.disabled instanceof Function &&
                                  node.checkbox.disabled(node)
                              );

                          if (isDisabled) {
                              disabledCheckboxesExist = true;
                          }

                          if (node.checkbox && !node.checkbox.checked && !isDisabled) {
                              allChecked = false;
                              return false;
                          }
                      });

                      if (allChecked) {
                          return disabledCheckboxesExist ?
                              i18next.t('treePicker.contextMenu.unselectAllEnabled') :
                              i18next.t('treePicker.contextMenu.unselectAll');
                      }

                      return disabledCheckboxesExist ?
                              i18next.t('treePicker.contextMenu.selectAllEnabled') :
                              i18next.t('treePicker.contextMenu.selectAll');
                  },
                  visible: function() {
                      tree = getTree();

                      if (!tree || !tree.allNodes) {
                          return false;
                      }

                      var elementOID = contextmenu.$element.data('id');
                      var treeNode = tree.allNodes[elementOID];

                      return (treeNode.children || []).length;
                  },
                  click: function() {
                      tree = getTree();

                      if (!tree || !tree.allNodes) {
                          return;
                      }

                      var elementOID = contextmenu.$element.data('id');
                      var treeNode = tree.allNodes[elementOID];
                      var changedNodes = [];
                      var allChecked = true;

                      tree.walk(treeNode, function (node) {
                          var isDisabled = node.checkbox &&
                              (
                                  node.checkbox.disabled === true ||
                                  node.checkbox.disabled instanceof Function &&
                                  node.checkbox.disabled(node)
                              );

                          if (node.checkbox && !node.checkbox.checked && !isDisabled) {
                              allChecked = false;
                              return false;
                          }
                      });

                      tree.walk(treeNode, function(node) {
                          if (!node || !node.checkbox) {
                              return;
                          }

                          var isDisabled = node.checkbox &&
                              (
                                  node.checkbox.disabled === true ||
                                  node.checkbox.disabled instanceof Function &&
                                  node.checkbox.disabled(node)
                              );

                          if (isDisabled) {
                              return;
                          }

                          var isChecked = node.checkbox.checked;

                          if (allChecked && isChecked) {
                              tree.uncheckNode(node);
                              changedNodes.push(node);
                          } else if (!isDisabled && !allChecked && !isChecked) {
                              tree.checkNode(node);
                              changedNodes.push(node);
                          }
                      });

                      var data = {
                          node: treeNode,
                          nodes: changedNodes,
                          instance: tree
                      };

                      if (allChecked) {
                          $tree.trigger('uncheck-nodes.tree', data);
                      } else {
                          $tree.trigger('check-nodes.tree', data);
                      }
                  }
              }
          ]
        });
    };

    Tools.CutConsecutivelyWhitespaces = function(str, maxWhitespaces) {
        if (!str) {
            return str;
        }

        var regex = new RegExp(' {' + (maxWhitespaces + 1) + ',}', 'gm');

        return str.replace(regex, ' '.repeat(maxWhitespaces));
    };

    Tools.IsValidVersionString = function(str) {
        return /\d+\.\d+\.\d+(\.\d+)?/.test(str);
    };

    Tools.ZeroFillStringArray = function(a, bLength) {
        var remaining = bLength - a.length;

        while (remaining > 0) {
            a.push("0");
            remaining--;
        }

        return a;
    };

    Tools.CompareVersion = function(versionA, versionB) {
        if (!versionA && !versionB) {
            return 0;
        }

        if (!versionA) {
            return -1;
        }

        if (!versionB) {
            return 1;
        }

        if (!Tools.IsValidVersionString(versionA) || !Tools.IsValidVersionString(versionB)) {
            return 0;
        }

        var versionAStringParts = versionA.split('.');
        var versionBStringParts = versionB.split('.');
        var versionAParts = Tools.ZeroFillStringArray(versionAStringParts, versionBStringParts.length)
                                   .map(function (s) { return parseInt(s, 10); });
        var versionBParts = Tools.ZeroFillStringArray(versionBStringParts, versionAStringParts.length)
                                   .map(function (s) { return parseInt(s, 10); });
        var versionAPartsLength = versionAParts.length;
        var versionBPartsLength = versionBParts.length;

        for (var i = 0; i < versionAPartsLength; i++) {
            if (versionBPartsLength == i) {
                return 1;
            }

            if (versionAParts[i] == versionBParts[i]) {
                continue;
            }

            return versionAParts[i] > versionBParts[i] ? 1 : -1;
        }

        if (versionAPartsLength != versionBPartsLength) {
            return -1;
        }

        return 0;
    };

    Tools.IsSet = function(obj) {
        return typeof obj !== 'undefined' && obj !== null;
    };

    Tools.GetElementHierarchy = function (elementOID, withoutSelf) {
        var element = DataManager.OrganizationUnitLoader.Data.DataMap[elementOID];
        var hierarchy = [];

        if (!element) {
            return i18next.t('misc.unknown');
        }

        if (withoutSelf) {
            element = element.Parent;
        }

        while (element && hierarchy.length <= 2) {
            hierarchy.unshift(element.Title);

            element = element.Parent;
        }

        return hierarchy.join(' › ');
    };

    Tools.OnImageNotFound = function () {
        var $this = $(this);
        var isSvgImage = $this.prop('tagName').toLowerCase() === 'image';
        var placeholderSrc = './img/file_not_found.svg';

        if (isSvgImage) {
            $this.parent().replaceWith('<img src="{0}" width="75">'.format(placeholderSrc));
            return;
        }

        $this.attr({
            src: placeholderSrc,
            width: 75
        });
    };

    Tools.handleHttpError = function (action, xhr) {
        if (!xhr) {
            return $.Deferred().reject().promise();
        }

        if (xhr.statusText === 'abort') {
            return $.Deferred().reject().promise();
        }

        if (xhr.status !== 0) {
            if (xhr.status < 400) {
                return $.Deferred().reject().promise();
            }

            if (xhr.status === Enums.HttpStatusCode.Unauthorized) {
                var title;

                switch ((xhr.responseJSON || {}).UnauthorizedType) {
                    case 0:
                        title = i18next.t('messages.unauthorized.LoginInvalid');
                        break;
                    case 1:
                        title = i18next.t('messages.unauthorized.CsrfTokenInvalid');
                        break;
                    case 2:
                        title = i18next.t('messages.unauthorized.SessionUnknown');
                        break;
                    case 3:
                        title = i18next.t('messages.unauthorized.DeserializeError');
                        break;
                    case 4:
                        title = i18next.t('messages.unauthorized.CookieFormatError');
                        break;
                    case 5:
                        title = i18next.t('messages.unauthorized.ValidationError');
                        break;
                    default:
                        title = i18next.t('messages.unauthorized.titleDefault');
                        break
                }

                Tools.Message.Show({
                    title: title,
                    text: i18next.t('messages.unauthorized.text'),
                    ok: true,
                    onOk: function () {
                        ChangeMode.Catalog.Hide();

                        content.saveQuestion(function () {
                            content.parameters.window.saveQuestion(function () {
                                $('#user-menu-dropdown').addClass('hide');
                                $('#topbar .user-field').removeClass('user-field-active');

                                if (changemode.active) {
                                    changemode.deactivate(function () {
                                        logout();
                                    }, true);
                                } else {
                                    logout();
                                }
                            });
                        });
                    }
                });

                return $.Deferred().reject().promise();
            } else if (xhr.status === Enums.HttpStatusCode.Forbidden) {
                /**
                 * @property {Enums.ForbiddenResponseErrorCode} ErrorCode
                 * @property {string} Message Stellt eine allgemeine Fehlermeldung bereit.
                 * @property {string} MimeType Gibt den unerlaubten MimeType an.
                 */
                var forbiddenResponse = !!xhr.responseText ? JSON.parse(xhr.responseText) : null;

                if (forbiddenResponse) {
                    if (forbiddenResponse.ErrorCode === Enums.ForbiddenResponseErrorCode.FileType) {
                        Tools.Message.Show({
                            title: i18next.t('messages.fileUpload_typeForbidden.title'),
                            text: i18next.t('messages.fileUpload_typeForbidden.text', { mimeType: forbiddenResponse.MimeType }),
                            ok: true
                        });
                    } else if (forbiddenResponse.ErrorCode === Enums.ForbiddenResponseErrorCode.InsufficientRights ||
                        forbiddenResponse.ErrorCode === Enums.ForbiddenResponseErrorCode.MissingRootElement ||
                        forbiddenResponse.ErrorCode === Enums.ForbiddenResponseErrorCode.MissingLicense) {
                        Tools.Message.Show({
                            title: action === Enums.HttpActionType.Read ?
                                i18next.t('messages.accessForbidden_read.title') :
                                i18next.t('messages.accessForbidden_write.title'),
                            text: action === Enums.HttpActionType.Read ?
                                i18next.t('messages.accessForbidden_read.text') :
                                i18next.t('messages.accessForbidden_write.text'),
                            ok: true
                        });
                    }

                    return $.Deferred().reject().promise();
                }

                Tools.Message.Show({
                    title: action === Enums.HttpActionType.Read ?
                        i18next.t('messages.accessForbidden_read.title') :
                        i18next.t('messages.accessForbidden_write.title'),
                    text: action === Enums.HttpActionType.Read ?
                        i18next.t('messages.accessForbidden_read.text') :
                        i18next.t('messages.accessForbidden_write.text'),
                    ok: true
                });

                return $.Deferred().reject().promise();
            } else if (xhr.status === Enums.HttpStatusCode.Conflict &&
                xhr.responseText === 'No licenses available') {
                Tools.Message.Show({
                    title: i18next.t('changeMode.messages.noLicensesAvailable.title'),
                    text: i18next.t('changeMode.messages.noLicensesAvailable.text'),
                    ok: true
                });

                return $.Deferred().reject().promise();
            } else if (xhr.status === Enums.HttpStatusCode.Request_Too_Long) {
                var response;

                try {
                    response = !!xhr.response ? JSON.parse(xhr.response) : {MaxFileSize: 0}
                } catch (e) {
                    response = null;
                }

                var fileSizeInMB = response ? (response.MaxFileSize / 1024).toFixed(2) : null;
                var text = fileSizeInMB ?
                    i18next.t('messages.entityTooLarge.textWithFileSize', {fileSizeInMB: fileSizeInMB}) :
                    i18next.t('messages.entityTooLarge.text', {fileSizeInMB: fileSizeInMB});

                Tools.Message.Show({
                    title: i18next.t('messages.entityTooLarge.title'),
                    text: text,
                    ok: true
                });

                return $.Deferred().reject().promise();
            }
        }

        if (action === Enums.HttpActionType.Read) {
            Tools.Message.Show({
                title: i18next.t('messages.couldNotLoadData.title'),
                text: i18next.t('messages.couldNotLoadData.text'),
                ok: true
            });
        } else if (action === Enums.HttpActionType.Write) {
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.couldNotSaveData.title'),
                text: i18next.t('changeMode.messages.couldNotSaveData.text'),
                ok: true
            });
        } else if (action === Enums.HttpActionType.Delete) {
            Tools.Message.Show({
                title: i18next.t('changeMode.messages.couldNotDeleteData.title'),
                text: i18next.t('changeMode.messages.couldNotDeleteData.text'),
                ok: true
            });
        } else if (action === Enums.HttpActionType.SendMails) {
            Tools.Message.Show({
                title: i18next.t('messages.couldNotSendMails.title'),
                text: i18next.t('messages.couldNotSendMails.text'),
                ok: true
            });
        }

        return $.Deferred().reject().promise();
    };

    Tools.ExternalDataCreationExampleWindow = (function () {
        var $window = $('#external-data-creation-example-window');
        var $overflow = $('#overflow');
        var $tabControl = $window.find('.top-menu');
        var $tabs = $window.find('.content');

        var configuration = {};

        function highlightSyntax(jsonString) {
            if (!jsonString) {
                return '';
            }

            jsonString = jsonString
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;');

            var replacerRegexp = /("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\-]?\d+)?)/g;

            return jsonString.replace(replacerRegexp, function (match) {
                var cls = 'number';

                if (/^"/.test(match)) {
                    if (/:$/.test(match)) {
                        cls = 'key';
                    } else {
                        cls = 'string';
                    }
                } else if (/true|false/.test(match)) {
                    cls = 'boolean';
                } else if (/null/.test(match)) {
                    cls = 'null';
                }

                return [
                    '<span class="',
                    cls,
                    '">',
                    match,
                    '</span>'
                ].join('');
            });
        }

        function show(options) {
            if (!options || !options.Tabs) {
                return;
            }

            if (options.Tabs.Issue) {
                initTab(options.Tabs.Issue, 'issue');
            }

            if (options.Tabs.Recorditems) {
                initTab(options.Tabs.Recorditems, 'recorditems');
            }

            if (options.Tabs.Mappings) {
                initTab(options.Tabs.Mappings, 'mappings');
            }

            initLanguage(options);
            unbindEvents();
            bindEvents();

            $tabControl.find('.tab-item:not(.hide):first').click();

            $overflow.removeClass('hide');
            $window.removeClass('hide');
            $window.find('.content').scrollTop(0);
        }

        function initLanguage(options) {
            $window.find('.title').text(options.Captions.Window);
            $tabs.find('.separator[data-node="description"] .caption').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.description'));
            $tabs.find('.separator[data-node="code-example"] .caption').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.example'));
            $tabs.find('.separator[data-node="model"] .caption').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.model'));
            $tabs.find('.separator[data-node="mappings"] .caption').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.mappings'));

            $tabs.find('.tab[data-tab="issue"] .btn-create').text(i18next.t('changeMode.panels.info.developerInformation.createIssue.exampleWindow.createIssue'));
            $tabs.find('.tab[data-tab="recorditems"] .btn-create').text(i18next.t('changeMode.panels.info.developerInformation.createRecorditems.exampleWindow.createRecorditems'));

            $tabs.find('.table[data-node="model"] thead tr').each(function () {
                $(this).find('th:eq(0)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.attribute'));
                $(this).find('th:eq(1)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.type'));
                $(this).find('th:eq(2)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.isRequired'));
                $(this).find('th:eq(3)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.remarks'));
            });

            $tabs.find('.table[data-node="mappings"] thead tr').each(function () {
                $(this).find('th:eq(0)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.elementTitle'));
                $(this).find('th:eq(1)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.elementType'));
                $(this).find('th:eq(2)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.elementIdentifier'));
                $(this).find('th:eq(3)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.resubitemIdentifier'));
                $(this).find('th:eq(4)').text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.row'));
            });
        }

        function initTab(options, tabId) {
            var $tabItem = $tabControl.find('[data-tab="'+tabId+'"]');
            var $tab = $tabs.find('[data-tab="'+tabId+'"]');

            $tabItem
                .text(options.TabControlCaption)
                .removeClass('hide');

            if (!!options.Description) {
                $tab.find('[data-node="description"]').removeClass('hide');
                $tab.find('.description').html(options.Description);
            } else {
                $tab.find('[data-node="description"]').addClass('hide');
            }

            var jsonString = typeof options.ExampleData === 'string' ?
                options.ExampleData :
                JSON.stringify(options.ExampleData, null, 4);

            $tab.find('.btn-create').toggleClass('hide', !jsonString || jsonString === '[]');

            if (!configuration.hasOwnProperty(tabId)) {
                configuration[tabId] = {};
            }

            configuration[tabId].ServiceConfiguration = options.Service;
            configuration[tabId].Example = jsonString;

            $tab.find('.separator[data-node="code-example"]').addClass('collapsed');
            $tab.find('pre.code').addClass('hide');

            var highlightedString = highlightSyntax(jsonString);

            $tab.find('.code').html(highlightedString);

            if ((options.Model || []).length) {
                $tab.find('[data-node="model"]').removeClass('hide');
                $tab.find('> .table[data-node="model"] tbody').html(createModelTable(options.Model));
            } else {
                $tab.find('[data-node="model"]').addClass('hide');
                $tab.find('> .table[data-node="model"] tbody').empty();
            }

            if ((options.Mappings || []).length) {
                $tab.find('[data-node="mappings"]').removeClass('hide');
                $tab.find('> .table[data-node="mappings"] tbody').html(createMappingsTable(options.Mappings));
            } else {
                $tab.find('[data-node="mappings"]').addClass('hide');
                $tab.find('> .table[data-node="mappings"] tbody').empty();
            }
        }

        function createModelTable(model) {
            var html = [];

            model.forEach(function (attribute) {
                html.push(
                    '<tr>',
                        '<td>{0}</td>'.format(attribute.Attribute),
                        '<td>{0}</td>'.format(Tools.escapeHtml(attribute.Type)),
                        '<td>{0}</td>'.format(attribute.IsRequired ? i18next.t('misc.yes') : i18next.t('misc.no')),
                        '<td>{0}</td>'.format(attribute.Remarks || ''),
                    '</tr>'
                );

                if ((attribute.SubType || []).length) {
                    html.push(
                        '<tr>',
                            '<td colspan="4">',
                                '<table class="table" data-node="model">',
                                    '<colgroup>',
                                        '<col style="width:30%;" />',
                                        '<col style="width:15%;" />',
                                        '<col style="width:10%;" />',
                                        '<col style="width:45%;" />',
                                    '</colgroup>',
                                    '<thead>',
                                        '<tr>',
                                            '<th></th>',
                                            '<th></th>',
                                            '<th></th>',
                                            '<th></th>',
                                        '</tr>',
                                    '</thead>',
                                    '<tbody>',
                                        createModelTable(attribute.SubType),
                                    '</tbody>',
                                '</table>',
                            '</td>',
                        '</tr>'
                    );
                }
            });

            return html.join('');
        }

        function createMappingsTable(mappings) {
            var html = [];

            function getHierarchy(element) {
                var hierarchy = [];

                while ((element = element.Parent)) {
                    hierarchy.unshift(element.Title);

                    if (element.Type === Enums.elementType.Form) {
                        break;
                    }
                }

                return hierarchy.join(' › ');
            }

            var form;

            mappings.forEach(function (mapping) {
                var element = changemode.Elements[mapping.ElementOID];

                if (element.Type === Enums.elementType.Form) {
                    form = element;
                }

                var elementCell = ['<td'];

                if (element.Type < 100) {
                    elementCell.push(' class="');

                    if (element.Type === Enums.elementType.Form) {
                        elementCell.push('form');
                    } else {
                        elementCell.push('cp-group');
                    }

                    elementCell.push('"');
                }

                elementCell.push(
                    '><span class="element-title">',
                    element.Title,
                    '</span><p class="smaller-text">',
                    getHierarchy(element),
                    '</p></td>'
                );

                var isForm = element.Type === Enums.elementType.Form;
                var isGroup = element.Type >= 93 && element.Type <= 99;
                var isCheckpoint = element.Type >= 100;

                var type;

                if (isForm) {
                    type = '<b><i>' + i18next.t('changeMode.panels.info.developerInformation.exampleWindow.form')
                } else if (isGroup) {
                    type = '<i>' + Tools.element.getTypeName(element, form);
                } else if (isCheckpoint) {
                    type = Tools.element.getTypeName(element, form);
                }

                type += ' (' + element.Type + ')';

                if (isForm) {
                    type += '</i></b>';
                } else if (isGroup) {
                    type += '</i>';
                }

                html.push(
                    '<tr', isGroup ? ' class="group-row"' : '', '>',
                        elementCell.join(''),
                        '<td>{0}</td>'.format(type),
                        '<td>{0}</td>'.format(element.OID),
                        '<td>{0}<p class="smaller-text italic">{1}</p></td>'.format(mapping.ResubmissionitemOID, mapping.ParentOID || ''),
                        '<td>{0}</td>'.format(mapping.Row || ''),
                    '</tr>'
                );
            });

            return html.join('');
        }

        function unbindEvents() {
            $window.find('.close').off('click');
            $tabControl.find('.tab-item').off('click');
            $tabs.find('.separator').off('click');
            $tabs.find('.btn-create').off('click');
        }

        function bindEvents() {
            $window.find('.close').on('click', hide);
            $tabControl.find('.tab-item').on('click', onTabItemClick);
            $tabs.find('.separator').on('click', onSeparatorClick);
            $tabs.find('.btn-create').on('click', onCreateButtonClick);
        }

        function onTabItemClick() {
            var $this = $(this);
            var id = $this.data('tab');

            if (!id) {
                return;
            }

            var $tab = $tabs.find('[data-tab="'+id+'"]');

            if (!$tab.length) {
                return;
            }

            $this
                .addClass('active')
                .siblings()
                .removeClass('active');

            $tab
                .removeClass('hide')
                .siblings()
                .addClass('hide');

            $window.find('.content').scrollTop(0);
        }

        function onSeparatorClick() {
            var $this = $(this);

            $this.toggleClass('collapsed');

            var isCollapsed = $this.hasClass('collapsed');
            var node = $this.data('node');
            var $tab = $this.parents('.tab');

            $tab.find('[data-node="'+node+'"]:not(.separator)').toggleClass('hide', isCollapsed);
        }

        function onCreateButtonClick() {
            var $this = $(this);
            var tabId = $this.parents('.tab').data('tab');

            if (!tabId || !configuration.hasOwnProperty(tabId)) {
                return;
            }

            var exampleData = configuration[tabId].Example;
            var serviceConfiguration = configuration[tabId].ServiceConfiguration;

            if (!exampleData || !serviceConfiguration) {
                return;
            }

            $this.text(i18next.t('changeMode.panels.info.developerInformation.exampleWindow.pleaseWait'));

            function successCallback(response, text, xhr) {
                $this.text('{0} {1}'.format(xhr.status, xhr.statusText));
            }

            function errorCallback(xhr) {
                if (tabId === 'issue') {
                    $tabs.find('.tab[data-tab="issue"] .btn-create')
                        .text(i18next.t('changeMode.panels.info.developerInformation.createIssue.exampleWindow.createIssue'));
                } else if (tabId === 'recorditems') {
                    $tabs.find('.tab[data-tab="recorditems"] .btn-create')
                        .text(i18next.t('changeMode.panels.info.developerInformation.createRecorditems.exampleWindow.createRecorditems'));
                }

                Tools.handleHttpError(Enums.HttpActionType.Write, xhr);
                Tools.Spinner.hide();
            }

            switch (serviceConfiguration.HttpMethod) {
                case 'POST':
                    Tools.http.post(serviceConfiguration.Method, exampleData, successCallback, errorCallback);
                    break;
                case 'PUT':
                    Tools.http.put(serviceConfiguration.Method, exampleData, successCallback, errorCallback);
                    break;
            }
        }

        function hide() {
            $overflow.addClass('hide');

            $window
                .addClass('hide')
                .find('.close')
                .off('click');
        }

        return {
            show: show,
            hide: hide
        };
    })();

    Tools.generateRandomNumber = function (minimum, maximum) {
        return Math.round(Math.random() * (maximum - minimum) + minimum);
    };

    Tools.createActionDropdown = function (items, $container) {
        var html = [
            '<div class="action-dropdown is-visible" style="max-height: 0;" title="">',
                '<ul class="action-list">',
        ];

        html.push((items || []).map(function renderItem(item) {
            var hasChildItems = (item.Children || []).length;
            var itemHtml = [
                '<li class="action-item',
                (hasChildItems ? ' has-children' : ''),
                (item.Checked ? ' checked' : ''),
                '" data-id="',
                item.ID,
                '">'
            ];

            if (item.FileInput) {
                itemHtml.push(
                    '<input type="file" class="file-upload jquery-upload" name="files-upload"{0}{1}>'
                        .format(
                            !!item.FileInput.Accept ?
                                ' accept="{0}"'.format(item.FileInput.Accept) :
                                '',
                            item.FileInput.Multiple ? ' multiple' : ''
                        )
                );
            }

            itemHtml.push(
                !!item.Icon ? '<img src="{0}" />'.format(item.Icon) : '',
                '<span class="caption">', item.Caption, '</span>'
            );

            if (hasChildItems) {
                itemHtml.push(
                    '<ul>',
                    item.Children.map(renderItem).join(''),
                    '</ul>'
                );
            }

            itemHtml.push('</li>');

            return itemHtml.join('');
        }).join(''));

        html.push(
                '</ul>',
            '</div>'
        );

        $container.find('.action-dropdown').remove();
        $container.append(html.join(''));

        var height = 0;

        (items || []).forEach(function iterate(item) {
            var $item = $container.find('li[data-id="{0}"]'.format(item.ID));

            height += $item.outerHeight();

            $item.on('click', function (evt) {
                evt.stopPropagation();

                if (item.OnClick instanceof Function) {
                    item.OnClick();
                }

                $container.removeClass('active');
                $container
                    .find('.action-dropdown')
                    .removeClass('is-visible')
                    .css('max-height', 0);
            });

            (item.Children || []).forEach(iterate);
        });

        $container.find('.action-dropdown').css('max-height', height);
    };

    Tools.GetBadgeCounterText = function (count) {
        if (isNaN(count) || count < 0) {
            return null;
        }

        return count >= 1000 ? '{0}k'.format(Math.floor(count / 1000)) : count;
    };

    Tools.ResolveUrlPattern = function (hash, pattern) {
        if (ApplicationRoutes[pattern] !== undefined) {
            return { pattern: pattern };
        } else {
            var patterns = [];

            for (pattern in ApplicationRoutes) {
                patterns.push(pattern);
            }

            patterns.sort(function (a, b) {
                if (a.toLowerCase() < b.toLowerCase()) {
                    return 1;
                } else if (a.toLowerCase() > b.toLowerCase()) {
                    return -1;
                } else {
                    return 0;
                }
            });

            for (var i = 0, len = patterns.length; i < len; i++) {
                var pattern = patterns[i];

                if (ApplicationRoutes.hasOwnProperty(pattern)) {
                    var regEx = new RegExp(pattern, 'i');
                    var match = regEx.exec(hash);

                    if (match !== null) {
                        return { pattern: pattern, args: match }
                    }
                }
            }
        }
    };

    Tools.ComparePrintOptions = function (optionsA, optionsB) {
        if (!Tools.IsSet(optionsA) !== !Tools.IsSet(optionsB)) {
            return false;
        }

        //Alle Druckoptionen sammeln
        var keys = {};
        for (var key in optionsA) {
            if (optionsA.hasOwnProperty(key)) {
                keys[key] = true;
            }
        }

        for (var key in optionsB) {
            if (optionsB.hasOwnProperty(key)) {
                keys[key] = true;
            }
        }

        for (var key in keys) {
            var valueA = optionsA[key];
            var valueB = optionsB[key];

            if (!Tools.IsSet(valueA)) {
                valueA = PrintOptionsPicker.GetDefaultPrintOptionValue(key);
            }

            if (!Tools.IsSet(valueB)) {
                valueB = PrintOptionsPicker.GetDefaultPrintOptionValue(key);
            }

            if ((typeof valueA === 'boolean' ||
                typeof valueB === 'boolean')) {
                //NULL oder Undefined wird ebenfalls als false betrachtet
                if (!Tools.IsSet(valueA)) {
                    valueA = false;
                }

                if (!Tools.IsSet(valueB)) {
                    valueB = false;
                }
            }

            if (valueA !== valueB) {
                return false;
            }
        }

        return true;
    };

    Tools.GetIssueAbbreviation = function (issue) {
        if (!issue) {
            return null;
        }

        if (issue.IsTemplate) {
            return i18next.t('issueTypes.template_abbreviation');
        }

        switch (issue.Type) {
            case Enums.issueType.Note:
                return i18next.t('issueTypes.note_abbreviation');
            case Enums.issueType.Disturbance:
                return i18next.t('issueTypes.disturbance_abbreviation');
            case Enums.issueType.DisturbancesCompleted:
                return i18next.t('issueTypes.disturbancesCompleted_abbreviation');
            case Enums.issueType.Inspection:
                return i18next.t('issueTypes.inspection_abbreviation');
            case Enums.issueType.Investigation:
                return i18next.t('issueTypes.investigation_abbreviation');
            case Enums.issueType.Survey:
                return i18next.t('issueTypes.survey_abbreviation');
            default:
                return i18next.t('issueTypes.task_abbreviation');
        }
    };

    Tools.IsTabEnabledInMenu = function (menuId, tabId) {
        if (!menuId || !tabId) {
            return false;
        }

        if (!Client.OfficeConfiguration) {
            return false;
        }

        var menuConfiguration = Client.OfficeConfiguration[menuId];

        if (!menuConfiguration) {
            return false;
        }

        var enabledTabs = menuConfiguration ? menuConfiguration.EnabledTabs : null;

        if (!(enabledTabs || []).length) {
            return false;
        }

        return enabledTabs.indexOf(tabId) > -1;
    };

    Tools.IsPropertyDisabledInMenuTab = function (menuId, tabId, propertyId) {
        if (!menuId || !tabId || !propertyId) {
            return false;
        }

        if (!Client.OfficeConfiguration) {
            return false;
        }

        var menuConfiguration = Client.OfficeConfiguration[menuId];

        if (!menuConfiguration) {
            return false;
        }

        if (!Object.keys(menuConfiguration.HiddenProperties || {}).length) {
            return false;
        }

        if (!menuConfiguration.HiddenProperties.hasOwnProperty(tabId)) {
            return false;
        }

        return (menuConfiguration.HiddenProperties[tabId] || []).indexOf(propertyId) > -1;
    };

    Tools.IsPropertyEnabledInMenuTab = function (menuId, tabId, propertyId) {
        return !Tools.IsPropertyDisabledInMenuTab(menuId, tabId, propertyId);
    };

    Tools.GetElementDataForService = function (element) {
        if (element.Address &&
            !element.Address.Street &&
            !element.Address.StreetNumber &&
            !element.Address.ZipCode &&
            !element.Address.StateNumber &&
            !element.Address.Name &&
            !element.Address.Phone &&
            !element.Address.WebAddress &&
            !element.Address.EmailSignature) {
            element.Address = null;
        }

        if (element.Type === Enums.elementType.Info) {
            element.PrintAlways = true;
        }

        if (element.Type !== Enums.elementType.Checkbox &&
            element.Type !== Enums.elementType.Number &&
            element.Type !== Enums.elementType.Line &&
            element.Type !== Enums.elementType.Memo &&
            element.Type !== Enums.elementType.Date &&
            element.Type !== Enums.elementType.Time &&
            element.Type !== Enums.elementType.ListBox &&
            element.Type !== Enums.elementType.PhoneNumber &&
            element.Type !== Enums.elementType.EMailAddresses ||
            element.Parent &&
            element.Parent.Parent &&
            element.Parent.Parent.IsSurvey &&
            (element.Parent.Parent.Type === Enums.elementType.Form ||
                element.Parent.Parent.Type === Enums.elementType.FormTemplate)) {
            element.Formula = null;
        }

        var infoText = DOMPurify.sanitize(element.InfoText);

        var data = {
            Actions: element.Actions ? Tools.clone(element.Actions) : null,
            AdditionalProperties: element.AdditionalProperties ? Tools.clone(element.AdditionalProperties): null,
            AdditionalSettings: element.AdditionalSettings || null,
            Address: element.Address ? Tools.clone(element.Address) : null,
            AllowOverrideFormulaValue: element.AllowOverrideFormulaValue || false,
            Attribute: element.Attribute || 0,
            CodingOID: element.CodingOID || null,
            CodingIdent: element.CodingIdent || null,
            Color: element.Color || null,
            CostCenter: element.CostCenter || null,
            Decimals: typeof element.Decimals !== 'undefined' ? element.Decimals : null,
            DisableInAppEditing: element.DisableInAppEditing || false,
            Description: element.Description || null,
            Enabled: element.Enabled || false,
            EvaluatePeriodically: element.EvaluatePeriodically || false,
            Evaluation: element.Evaluation ? Tools.clone(element.Evaluation) : null,
            FilenameFormula: element.FilenameFormula || null,
            Files: element.Files ? Tools.clone(element.Files) : null,
            ForceParameterOrderWhileRecording: element.ForceParameterOrderWhileRecording || false,
            Formula: element.Formula || null,
            GradingScale: element.GradingScale ? Tools.clone(element.GradingScale) : null,
            Identcode: element.Identcode || null,
            IgnoreInEvaluation: element.IgnoreInEvaluation || false,
            ImageOID: element.ImageOID || null,
            InfoText: !!infoText ? infoText : null,
            InitialStateOID: element.InitialStateOID || null,
            IsBranchStore: element.IsBranchStore || false,
            IsInspection: element.IsInspection || false,
            IsInvestigation: element.IsInvestigation || false,
            IsRecordingLockable: element.IsRecordingLockable || false,
            IsRecordingLockedByDefault: element.IsRecordingLockedByDefault || false,
            IssueTitleFormula: element.IssueTitleFormula || null,
            IsSurvey: element.IsSurvey || false,
            IsWeighted: element.IsWeighted || false,
            Items: element.Items || null,
            Language: element.Language || null,
            Layout: element.Layout || null,
            MaxSubsampleCount: element.MaxSubsampleCount || null,
            MinSubsampleCount: element.MinSubsampleCount || null,
            ModifiedForms: element.ModifiedForms || null,
            OID: element.OID || null,
            ParentOID: element.Parent ? element.Parent.OID : (element.ParentOID || null),
            Position: element.Position,
            PrintAlways: element.PrintAlways || false,
            Properties: (element.Properties || []).length ? element.Properties : null,
            PrototypeOID: element.Prototype ? element.Prototype.OID : (element.PrototypeOID || null),
            ProvideToExternalApps: typeof element.ProvideToExternalApps === 'boolean' ? element.ProvideToExternalApps : false,
            QRCode: element.QRCode || null,
            ReferenceFormOID:  element.ReferenceFormOID || null,
            RepresentationType: element.RepresentationType || null,
            Required: element.Required || false,
            Requirements: element.Requirements || null,
            Scheduling: (element.Scheduling || []).length ? element.Scheduling : null,
            ShowAtFirstPosition: element.ShowAtFirstPosition || false,
            ShowInOverallResult: element.ShowInOverallResult || false,
            Structure: element.Structure ? Tools.clone(element.Structure) : null,
            StructureEval: element.StructureEval || null,
            SuggestedValues: element.SuggestedValues || null,
            Teams: element.Teams || null,
            TemporaryDeviationOffset: element.TemporaryDeviationOffset,
            Title: element.Title || null,
            Type: element.Type,
            UnitOID: element.UnitOID || null,
            UseFormGrading: element.UseFormGrading || false,
            Processes: element.Processes || null
        };

        return Tools.UnEscape.Element(data);
    };

    Tools.BlockSpecialCharactersInput = function (e) {
        // < > : "
        if (Tools.indexOf([34, 58, 60, 62], e.which) >= 0) {
            return false;
        }
    };

    Tools.GetAvailableAndVisibleForms = function (formMap, location, disableSurveys, disableInvestigations) {
        var availableForms = {};
        var visibleForms = {};

        if (!location) {
            return {
                AvailableForms: availableForms,
                VisibleForms: visibleForms
            };
        }

        (function walk(location) {
            (location.Forms || []).forEach(function (formIdentifier) {
                var form = formMap[formIdentifier];

                if (form) {
                    if (!(disableSurveys && form.IsSurvey) && !(disableInvestigations && form.IsInvestigation)) {
                        availableForms[formIdentifier] = true;
                    }

                    (function walkForm(form) {
                        if (visibleForms[form.OID]) {
                            return;
                        }

                        if (!(disableSurveys && form.IsSurvey)) {
                            visibleForms[form.OID] = true;

                            if (form.Parent) {
                                walkForm(form.Parent);
                            }
                        }
                    })(form);
                }
            });

            if (location.Parent) {
                walk(location.Parent);
            }
        })(location);

        return {
            AvailableForms: availableForms,
            VisibleForms: visibleForms
        };
    };

    Tools.GetAvailableAndVisibleSchedulings = function (schedulingMap, location, userMustBeAssigned) {
        return Tools.DataManager.FormLoader
            .GetAll()
            .then(function (formData) {
                var availableSchedulings = {};
                var visibleSchedulings = {};

                if (!location) {
                    return {
                        AvailableSchedulings: availableSchedulings,
                        VisibleSchedulings: visibleSchedulings
                    };
                }

                var addToVisibleSchedulings = function (scheduling) {
                    if (!scheduling ||
                        visibleSchedulings.hasOwnProperty(scheduling.OID)) {
                        return;
                    }

                    visibleSchedulings[scheduling.OID] = true;
                    addToVisibleSchedulings(scheduling.Parent);
                };

                var iterateScheduling = function (schedulingList) {
                    if (!(schedulingList || []).length) {
                        return;
                    }

                    for (var sCnt = 0, sLen = schedulingList.length; sCnt < sLen; sCnt++) {
                        var scheduling = schedulingMap[schedulingList[sCnt].OID];

                        if (!scheduling) {
                            continue;
                        }

                        if (availableSchedulings.hasOwnProperty(scheduling.OID)) {
                            continue;
                        }

                        if (!userMustBeAssigned) {
                            availableSchedulings[scheduling.OID] = true;
                            addToVisibleSchedulings(scheduling);
                            continue;
                        }

                        var containsUser = Tools.contains(scheduling.Users, User.OID);
                        var containsTeams = false;

                        if ((scheduling.Teams || []).length && (User.Teams || []).length) {
                            for (var t = 0; t < User.Teams.length; t++) {
                                if (Tools.contains(scheduling.Teams, User.Teams[t])) {
                                    containsTeams = true;
                                    break;
                                }
                            }
                        }

                        if (containsUser || containsTeams) {
                            addToVisibleSchedulings(scheduling);
                            availableSchedulings[scheduling.OID] = true;
                        }
                    }
                };

                (function traverse(elem) {
                    if (elem.Type !== Enums.elementType.Root &&
                        elem.Type !== Enums.elementType.Location) {
                        return;
                    }

                    if ((elem.Parametergroups || []).length) {
                        for (var gCnt = 0, gLen = elem.Parametergroups.length; gCnt < gLen; gCnt++) {
                            iterateScheduling(elem.Parametergroups[gCnt].Scheduling);
                        }
                    }

                    if ((elem.Forms || []).length) {
                        for (var fCnt = 0, fLen = elem.Forms.length; fCnt < fLen; fCnt++) {
                            var form = formData.Map[elem.Forms[fCnt]];

                            if (form) {
                                iterateScheduling(form.Scheduling);
                            }
                        }
                    }

                    (elem.Children || []).forEach(traverse);
                })(CurrentEntity);

                return {
                    AvailableSchedulings: availableSchedulings,
                    VisibleSchedulings: visibleSchedulings
                };
            });
    };

    Tools.UpdateOfficeUserSettings = function (key, value) {
        if (key == null) {
            return $.Deferred().reject().promise();
        }

        return Tools.http.post('office-usersettings', [{
            Key: key,
            Value: value
        }]).then(function () {
            User.OfficeSettings = User.OfficeSettings || {};
            User.OfficeSettings[key] = User.OfficeSettings[key] || { Key: key };
            User.OfficeSettings[key].Value = value;

            $(document).trigger('office-settings:changed', key);
        });
    };

    Tools.GetOfficeSettingValue = function (key) {
        if (!User || !User.OfficeSettings) {
            return null;
        }

        if (!User.OfficeSettings.hasOwnProperty(key)) {
            return null;
        }

        return User.OfficeSettings[key].Value;
    };

    Tools.GetAvailableAndVisibleStatusses = function (statusState, additionalFilterFn) {
        if (statusState == null) {
            statusState = Enums.StatusState.All;
        }

        var allStatusses = Object.values(Properties)
            .filter(function (prop) {
                return prop.Type === Enums.propertyType.Status;
            });

        if (statusState === Enums.StatusState.All) {
            var all = allStatusses
                .reduce(function (dictionary, status) {
                    dictionary[status.OID] = true;
                    return dictionary;
                }, {});

            return {
                AvailableStatusses: all,
                VisibleStatusses: all
            };
        }

        var availableStatusses = allStatusses
            .filter(function (status) {
                var isAvailable = statusState === Enums.StatusState.Open && !status.ClosedState ||
                    statusState === Enums.StatusState.Closed && status.ClosedState;

                if (!isAvailable) {
                    return false;
                }

                return additionalFilterFn instanceof Function ? additionalFilterFn(status) : isAvailable;
            })
            .reduce(function (dictionary, status) {
                dictionary[status.OID] = true;
                return dictionary;
            }, {});

        var visibleStatusses = {};

        Object.keys(availableStatusses)
            .forEach(function (identifier) {
                visibleStatusses[identifier] = true;

                var status = Properties[identifier];

                if (!status.ParentOID) {
                    return;
                }

                if (visibleStatusses[status.ParentOID]) {
                    return;
                }

                (function walk(visibleStatus) {
                    if (!visibleStatus) {
                        return;
                    }

                    if (visibleStatusses[visibleStatus.OID]) {
                        return;
                    }

                    visibleStatusses[visibleStatus.OID] = true;
                    walk(Properties[visibleStatus.ParentOID]);
                })(Properties[status.ParentOID]);
            });

        return {
            AvailableStatusses: availableStatusses,
            VisibleStatusses: visibleStatusses
        };
    }

    Tools.getAllRolesOfUser = function (userOID, useModifiedData) {
        var roles = useModifiedData ? changemode.Roles : Roles;
        var teams = useModifiedData ? changemode.Teams : Teams;
        var userRoles = []

        for (var teamOID in teams) {
            var team = teams[teamOID];

            if (team.ModificationType === Enums.ModificationType.Deleted || (team.Users || []).length === 0) {
                continue;
            }

            team.Users.forEach(function (user) {
                if (user.OID !== userOID) {
                    return;
                }

                user.Roles.forEach(function (roleOID) {
                    if (roles[roleOID] == null) {
                        return;
                    }

                    if (!Tools.contains(userRoles, roleOID, 'OID')) {
                        userRoles.push(roles[roleOID]);
                    }
                });
            });
        }

        return userRoles.length ? userRoles : null;
    };

    Tools.JoinDistinct = function (collectionA, collectionB) {
        if (!collectionA) {
            collectionA = [];
        }

        if (!(collectionA instanceof Array)) {
            return null;
        }

        if (!(collectionB instanceof Array)) {
            return collectionA;
        }

        collectionA = $.extend(true, [], collectionA);

        collectionB.forEach(function (item) {
            if (collectionA.indexOf(item) === -1) {
                collectionA.push(item);
            }
        });

        return collectionA;
    };

    Tools.HasIntersection = function (a, b) {
        if (!(a || []).length || !(b || []).length) {
            return false;
        }

        return a.some(function (value) { return b.indexOf(value) > -1; });
    };

    Tools.OnUploadError = function (event, context) {
        if (context) {
            Tools.handleHttpError(Enums.HttpActionType.Write, context.xhr);
        }

        Tools.Spinner.hide();
    };

    Tools.IsContactModuleAvailable = function () {
        return Client && Client.Licenses && (Client.Licenses.Contacts || 0) > 0;
    };

    Tools.IsEMailCpEnabled = function () {
        return Client && Client.Licenses &&
            Client.Licenses.Contacts > 0 &&
            (Client.Licenses.EnableCpTypeEMailAddresses || false);
    };

    Tools.IsValidMailAddress = function (value) {
        if (typeof value !== 'string') {
            return false;
        }

        value = value.trim();

        if (value.length < 0 || value.length > 254) {
            return false;
        }

        const regexp = /^((?:[^<>()[\]\.,;:\s@"']+(?:\.[^<>()[\]\.,;:\s@"']+)*)|(?:".+"))@((?!-)(?:[^<>()[\]\.,;:_\s@"']+\.)*?(?:[^<>()[\]\.,;:\s@"'](?!-)){2,})$/i;
        const result = regexp.exec(value);

        if (result == null || result.length !== 3) {
            return false;
        }

        const local = result[1];
        return !local.includes('..') && !local.includes('@');
    };

    Tools.SortSelectControlAlphabetically = function ($control) {
        if (!$control || !$control.length) {
            return;
        }

        var options = $control.find('option');
        var selectedOption = $control.val();

        options.sort(function (a, b) {
            var textA = a.text.toLowerCase();
            var textB = b.text.toLowerCase();

            if (textA > textB) return 1;
            if (textA < textB) return -1;
            return 0;
        });

        $control.empty().append(options);
        $control.val(selectedOption);
    };

    Tools.GetEmailCheckpointsFromElement = function (element) {
        return $.map(element || [], function(groups) {
            return ((groups || {}).Parameters || []).filter(function(el) {
                return el.Type === Enums.elementType.EMailAddresses;
            });
        });
    };

    /**
     * Durchläuft eine Liste und führt die asynchrone Aktionen nacheinander aus
     * @param {T[]} collection - Liste die durchlaufen werden soll
     * @param {function(item: T): JQuery.Promise} actionCallback - Die Aktion welche für jeden Eintrag in der Liste ausgeführt werden soll
     * @param {{Visible: boolean, Text: string, ScreenBlock: boolean = false} | null} progressbarOptions - Optionen ob und wie die Progressbar angezeigt werden soll
     * @returns {JQuery.Promise}
     * @constructor
     */
    Tools.ForEachDeferredAction = function (collection, actionCallback, progressbarOptions) {
        if (collection == null || actionCallback == null) {
            return $.Deferred().reject().promise();
        }

        if (collection.length === 0) {
            return $.Deferred().resolve().promise();
        }

        progressbarOptions = progressbarOptions || {Visible: false};

        var deferred = $.Deferred();

        var totalProgress = collection.length;
        var currentProgress = 0;

        if (progressbarOptions.Visible) {
            Tools.Progressbar.Start(totalProgress, progressbarOptions.Text, null, progressbarOptions.ScreenBlock);
            Tools.Progressbar.StartProgress();
        }

        (function loop() {
            if (progressbarOptions.Visible) {
                Tools.Progressbar.Set(currentProgress, totalProgress);
            }

            if (currentProgress >= totalProgress)
            {
                Tools.Progressbar.StopProgress();
                deferred.resolve();
                return;
            }

            var item = collection[currentProgress];
            currentProgress++;

            //TODO Die aufrufenden Stellen sollte um ein Fehlerhandling erweitert werden Ticket: 3872
            actionCallback(item)
                .then(loop, loop); //Bisheriges Verhalten: Fehler ignorieren
        })();

        return deferred.promise();
    };

    Tools.ShowRteContentSize = function ($area, value) {
        var contentLength = (value || $area.val()).length;
        var infoText = $area.closest('.content').find('.item-description');

        // alle Warn-Klassen entfernen
        infoText.attr('class', 'item-description');

        if (contentLength > 2097152) {
            infoText.addClass('fatal');
            infoText.text(i18next.t('changeMode.panels.properties.rteFatal', {
                size: (contentLength / 1048576).toFixed(2) + ' MB',
                returnObjects: false
            }));
        } else if (contentLength > 1048576) {
            infoText.addClass('warn');
            infoText.text(i18next.t('changeMode.panels.properties.rteWarn', {
                size: (contentLength / 1048576).toFixed(2) + ' MB',
                returnObjects: false
            }));
        } else if (contentLength > 512000) {
            infoText.text(i18next.t('changeMode.panels.properties.rteInfo', {
                size: (contentLength / 1024).toFixed(2) + ' KB',
                returnObjects: false
            }));
        } else {
            infoText.empty();
        }
    };

    Tools.ReEncodeImage = function (srcImage, mimeType) {
        mimeType = mimeType || 'image/jpeg';

        var defer = $.Deferred();
        var name;
        var returnAsFile = false;

        var processImage = function () {
            var image = this;
            var canvas = document.createElement('canvas');

            canvas.width = image.naturalWidth || image.width;
            canvas.height = image.naturalHeight || image.height;

            var context = canvas.getContext('2d');
            context.drawImage(image, 0, 0);

            if (returnAsFile) {
                canvas.toBlob(function (compressedBlob) {
                    compressedBlob.name = compressedBlob.name || name || 'image';
                    defer.resolve(compressedBlob);
                }, mimeType, 0.85);
            } else {
                var compressedImage = canvas.toDataURL(mimeType, 0.85);
                defer.resolve(compressedImage);
            }
        };

        if (typeof srcImage === 'string') {
            var image = new Image();

            image.onload = processImage;
            image.onerror = defer.reject;
            image.src = srcImage;
        } else if (srcImage instanceof Image) {
            processImage.call(srcImage);
        } else if (srcImage instanceof File) {
            var image = new Image();
            var urlCreator = window.URL || window.webkitURL;
            var imageUrl = urlCreator.createObjectURL(srcImage);

            returnAsFile = true;
            name = srcImage.name.replace('.png', '');
            image.onload = processImage;
            image.onerror = defer.reject;
            image.src = imageUrl;
        } else {
            defer.reject('bad format');
        }

        return defer.promise();
    };

    Tools.ReplacePngUrlData = function(markup) {
        var r = new RegExp('data:image\/png;base64,[^"!)]*', 'ig')
        var defer = $.Deferred();
        var processDeferreds = [];
        var hasChanges = false;
        var match;

        while (match = r.exec(markup)) {
            var data = match[0];
            var reEncodeDefer = Tools.ReEncodeImage(data)
                .then(function (compressedImage) {
                    markup = markup.replaceAll(data, compressedImage);
                    hasChanges = true;
                });

            processDeferreds.push(reEncodeDefer);
        }

        $.when.apply($,processDeferreds)
            .always(function () {
                defer.resolve(markup, hasChanges);
            });

        return defer.promise();
    };

    // Verfügbarkeit von Benutzernamen und Email-Adressen prüfen
    Tools.CheckAvailability = function (text, options, isAvailableCallback, onFail) {
        isAvailableCallback = isAvailableCallback || $.noop;
        onFail = onFail || $.noop;

        return Tools.http.get('availability/', function (msg) {
            if (Boolean(msg)) {
                isAvailableCallback();
            } else {
                onFail();
            }
        }, null, options);
    };

    Tools.GetAssignedUserLicensesCount = function () {
        if (!changemode.active) {
            return null;
        }

        if (!ressources.users.hasRight('CMU')) {
            return null;
        }

        var fullLicensesAssigned = 0;
        var limitedLicensesAssigned = 0;
        var viewRightLicensesAssigned = 0;

        for (var identifier in changemode.Users) {
            var user = changemode.Users[identifier];

            if (user.ModificationType === Enums.ModificationType.Deleted ||
                user.IsSystemUser ||
                user.IsLocked) {
                continue;
            }

            if (user.LicenseType === Enums.LicenseType.Full) {
                fullLicensesAssigned++;
            } else if (user.LicenseType === Enums.LicenseType.Limited) {
                limitedLicensesAssigned++;
            } else {
                viewRightLicensesAssigned++;
            }
        }

        return {
            FullLicensesAssigned: fullLicensesAssigned,
            LimitedLicensesAssigned: limitedLicensesAssigned,
            ViewRightLicensesAssigned: viewRightLicensesAssigned
        };
    };

    Tools.GetSelectableUsers = function (locationIdentifier) {
        var userCanAssignAllTeamsAndUsers = !!locationIdentifier ? ressources.users.hasRightAtLocation(
            Enums.Rights.IssueRights.AllowAssigningAllUsersToIssues,
            locationIdentifier
        ) : ressources.users.hasRight(Enums.Rights.IssueRights.AllowAssigningAllUsersToIssues);

        var users = $.map(Users || {}, function (user) { return user; });

        if (userCanAssignAllTeamsAndUsers) {
            return users;
        }

        var teamUsers = {};
        var location = !!locationIdentifier ? DataManager.OrganizationUnitLoader.Data.DataMap[locationIdentifier] : null;

        (User.Teams || []).forEach(function (oid) {
            var team = Teams[oid];

            if (!team) {
                return;
            }

            if (location && !ressources.tools.isTeamAssignedToLocation(team.OID, location, false)) {
                return;
            }

            team.Users.forEach(function (user) {
                teamUsers[user.OID] = true;
            });
        });

        return users.filter(function (user) {
            return user.OID === User.OID || teamUsers.hasOwnProperty(user.OID);
        });
    };

    Tools.GetSelectableTeams = function (locationIdentifier) {
        var userCanAssignAllTeamsAndUsers = !!locationIdentifier ? ressources.users.hasRightAtLocation(
            Enums.Rights.IssueRights.AllowAssigningAllUsersToIssues,
            locationIdentifier
        ) : ressources.users.hasRight(Enums.Rights.IssueRights.AllowAssigningAllUsersToIssues);
        var location = !!locationIdentifier ? DataManager.OrganizationUnitLoader.Data.DataMap[locationIdentifier] : null;

        var teams = $.map(Teams || {}, function (team) { return team; });
        var visibleTeams, selectableTeams;

        if (!userCanAssignAllTeamsAndUsers) {
            teams = teams.filter(function (team) {
                return (User.Teams || []).indexOf(team.OID) > -1 &&
                    (!location || ressources.tools.isTeamAssignedToLocation(team.OID, location, false));
            });

            visibleTeams = [];
            selectableTeams = teams.map(function (team) {
                return team.OID;
            });

            function addTeamToVisibleTeams(team) {
                if (visibleTeams.indexOf(team.OID) > -1) {
                    return;
                }

                visibleTeams.push(team.OID);

                if (!team.Parent) {
                    return;
                }

                addTeamToVisibleTeams(team.Parent);
            }

            teams.forEach(addTeamToVisibleTeams);
        }

        return {
            Teams: teams,
            VisibleTeams: visibleTeams,
            SelectableTeams: selectableTeams
        };
    }

    Tools.GetCountOfIssueResponsibilities = function (issueResponsibilities) {
        if (!issueResponsibilities) {
            return 0;
        }

        var usersCount = Object.keys(issueResponsibilities.Users || {}).length;
        var teamsCount = Object.keys(issueResponsibilities.Teams || {}).length;
        var contactsCount = Object.keys(issueResponsibilities.Contacts || {}).length;
        var contactGroupsCount = Object.keys(issueResponsibilities.ContactGroups || {}).length;

        return usersCount + teamsCount + contactsCount + contactGroupsCount;
    };

    /***
     * Bestimmt die Breite der Kindelemente eines jQuery-Elements.
     * @param $element {$}
     * @returns {number}
     */
    Tools.GetTotalWidthOfChildComponents = function ($element) {
        if (!$element || !($element instanceof $)) {
            return 0;
        }

        var $children = $element.children(':visible');

        if (!$children.length) {
            return 0;
        }

        var width = 0;

        $children.each(function (_, elem) {
            var $elem = $(elem);
            var grandChildrenWidth = Tools.GetTotalWidthOfChildComponents($elem);

            width += grandChildrenWidth || $elem.outerWidth(true);
        });

        return width;
    };

    /**
     * @param container {HTMLElement}
     * @param initialValue {string | null}
     * @param disableModifications {boolean}
     * @return {toastui.Editor | null}
     */
    Tools.CreateMarkdownEditor = function (container, initialValue, disableModifications) {
        if (!(container instanceof HTMLElement)) {
            return null;
        }

        disableModifications = disableModifications || false;

        var editorOptions = {
            el: container,
            language: User.Language,
            usageStatistics: false,
            initialEditType: 'wysiwyg',
            toolbarItems: [
                ['bold', 'italic', 'strike'],
                ['heading'],
                ['hr', 'quote'],
                ['ul', 'ol', 'indent', 'outdent'],
                ['link']
            ]
        };

        if (!!initialValue) {
            editorOptions.initialValue = initialValue;
        }

        if (disableModifications) {
            editorOptions.viewer = true;
        }

        return disableModifications ?
            toastui.Editor.factory(editorOptions) :
            new toastui.Editor(editorOptions);
    };

    Tools.RenderMarkdown = function (str) {
        if (!str) {
            return null;
        }

        var $container = $('<div></div>');

        toastui.Editor.factory({
            el: $container.get(0),
            initialValue: str,
            viewer: true
        });

        return $container.html();
    };

    Tools.GetPropertyKeys = function (obj, keyBase) {
        if (!obj || !keyBase) {
            return null;
        }

        var keys = Object.keys(obj);
        var props = [];

        for (var iCnt = 0, iLen = keys.length; iCnt < iLen; iCnt++) {
            var key = keys[iCnt];
            var prop = obj[key];
            var newKey = keyBase + '.' + key;

            if (typeof prop === 'object' && !(prop instanceof Array)) {
                props = props.concat(Tools.GetPropertyKeys(prop, newKey));
            } else {
                props.push(newKey);
            }
        }

        return props;
    };

    Tools.GetObjectPropertyByKeyPath = function (path, obj) {
        if (!path || !obj) {
            return null;
        }

        var splittedPath = path.split('.');

        for (var pCnt = 0, pLen = splittedPath.length; pCnt < pLen; pCnt++) {
            obj = obj[splittedPath[pCnt]];

            if (!obj) {
                return null;
            }
        }

        return obj;
    };

    /***
     * Prüft, ob ein Array die gleichen Einträge enthält. Dabei wird die Reihenfolge der Einträge ignoriert.
     * @param arrayA
     * @param arrayB
     * @returns {boolean}
     * @constructor
     */
    Tools.IsSetEqual = function(arrayA, arrayB) {
        if ((arrayA || []).length !== (arrayB || []).length) {
            return false;
        }

        // Beide haben die gleiche länge
        if ((arrayA || []).length === 0) {
            return true;
        }

        if (!arrayA.every(function(oid) { return arrayB.includes(oid)})){
            return false
        }

        // Wir müssen auch aus dieser Richtung testen, da ansonsten auch beim Vergleich vom folgenden Fall diese Funktion true zurückgeben würde.
        // var test1 = new Array(4);
        // var test2 = new Array(4);
        // test2[0] = 2;
        // Beide haben nämlich eine length von 4
        return arrayB.every(function(oid) { return arrayA.includes(oid)});
    }

    /**
     * @return {Date}
     */
    Tools.GetBuildTime = function () {
        var buildTime = '1728564948484';

        if (!isNaN(buildTime)) {
            if (typeof buildTime === 'string') {
                buildTime = parseInt(buildTime, 10);
            }

            buildTime = new Date(buildTime);
        }

        return buildTime;
    };

    Tools.GetValueByPath = function (attributePath, entity) {
        if (!attributePath || !entity) {
            return null;
        }

        var splittedName = attributePath.split('.');
        var attrNode = entity;

        while (splittedName.length > 1) {
            var attr = splittedName.shift();

            if (!attrNode[attr]) {
                attrNode[attr] = {};
            }

            attrNode = attrNode[attr];
        }

        var valueAttr = splittedName[0];

        return attrNode[valueAttr];
    };

    Tools.SetValueByPath = function (attributePath, entity, value, strictNullAndUndefinedCheck) {
        if (!attributePath || !entity) {
            return;
        }

        var splittedName = attributePath.split('.');
        var attrNode = entity;

        while (splittedName.length > 1) {
            var attr = splittedName.shift();

            if (!attrNode[attr]) {
                attrNode[attr] = {};
            }

            attrNode = attrNode[attr];
        }

        var valueAttr = splittedName[0];

        if ((strictNullAndUndefinedCheck && !Tools.IsSet(value)) ||
            (!strictNullAndUndefinedCheck && !value)) {
            if (attrNode) {
                delete attrNode[valueAttr];
            }

            return;
        }

        attrNode[valueAttr] = value;
    };

    Tools.Users = (function () {
        function getProfilePicturePath(user, ignoreProfilePicture) {
            if (!user) {
                return './img/user.svg';
            }

            if (!ignoreProfilePicture && user.ImageOID) {
                var image = Files[user.ImageOID];

                if (image) {
                    return Config.BaseUri + 'images/s/' + image.Filename;
                }
            }

            return './img/user.svg';
        }

        return {
            getProfilePicturePath: getProfilePicturePath
        };
    })();

    Tools.Comments = (function () {
        function renderComments(comments, rights) {
            var html = ['<div class="comments-list">'];

            if ((comments || []).length) {
                comments.forEach(function (comment) {
                    html.push(renderComment(comment, rights));
                });
            } else {
                html.push('<p>', i18next.t('comments.noComments'), '</p>');
            }

            html.push('</div>');

            return html.join('');
        }

        function renderComment(comment, rights) {
            if (!comment) {
                return null;
            }

            rights = rights || {};

            var creator = Users[comment.CreatorOID];
            var imagePath = Tools.Users.getProfilePicturePath(creator);
            var creationTimestamp = Tools.dateTime.getDifferenceString(comment.CreationTimestamp);
            var editor = Users[comment.EditorOID];
            var modificationTimestamp = Tools.dateTime.getDifferenceString(comment.ModificationTimestamp);

            var html = [
                '<div class="comment-wrapper', comment.CreatorOID === User.OID ? ' self' : '', '" data-oid="', comment.OID, '">',
                '<div class="comment">',
            ];

            if (comment.CreatorOID !== User.OID) {
                html.push(
                    '<div class="user-image">',
                    '<img data-user-oid="', comment.CreatorOID, '" src="', imagePath, '">',
                    '</div>',
                );
            }

            html.push(
                '<div class="content">',
                '<div class="text-wrapper">'
            );

            if (comment.CreatorOID !== User.OID) {
                html.push(
                    '<p class="user-title">',
                    creator ? creator.Title : i18next.t('misc.unknown'),
                    '</p>'
                );
            }

            var canEditComment = rights.userCanCreateComments && comment.CreatorOID === User.OID ||
                rights.userCanEditOrDeleteCommentsOfOthers;
            var clickToEditText = i18next.t('comments.clickToEdit');

            html.push(
                '<p class="text"', (canEditComment ? (' contenteditable="true" title="' + clickToEditText + '"') : ''),'>',
                comment.Text,
                '</p>',
                '</div>'
            );

            html.push(
                '<div class="footer">',
                '<p class="timestamp">', creationTimestamp, '</p>'
            );

            if (comment.CreationTimestamp.getTime() !== comment.ModificationTimestamp.getTime()) {
                html.push(
                    '<p class="modification-info">(',
                    i18next.t('comments.modifiedBy', {
                        editorTitle: editor ? editor.Title : i18next.t('misc.unknown'),
                        modificationTimestamp: modificationTimestamp
                    }),
                    ')</p>'
                );
            }

            if (canEditComment) {
                html.push(
                    '<div class="delete-comment" title="',
                    i18next.t('comments.deleteComment.hoverText'),
                    '"></div>'
                );
            }

            html.push(
                '</div>',
                '</div>',
                '</div>',
                '</div>'
            );

            return html.join('');
        }

        return {
            renderComments: renderComments,
            renderComment: renderComment
        };
    })();

    return (global.Tools = Tools);
})(window);