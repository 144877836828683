/**
 * @require ./_base.js
 */
(function (global) {
    /**
     * @param {number[]} options.Types
     * @param {boolean} options.WithDescendants
     * @param {boolean} options.WithPrototypes
     * @augments {DataLoaderBase}
     * @constructor
     */
    var ElementLoader = function (options) {
        var me = this;

        me.Method = 'elements?withDeactivatedElements=true';

        if (!options) {
            return;
        }

        if (options.WithPrototypes) {
            me.Method += '&withPrototypes=true'
        }

        if (options.WithDescendants) {
            me.Method += '&withDescendants=true'
        }

        if (options.Types instanceof Array) {
            options.Types.forEach(function (type) {
                me.Method += '&types=' + type;
            });
        }
    };

    ElementLoader.prototype = Object.create(global.DataLoaderBase.prototype, {
        constructor: ElementLoader
    });

    ElementLoader.prototype.Prepare = function () {
        var me = this;

        me.Data.Data = me.Data.Data.map(function (element) {
            return prepareElement.call(me, element);
        });

        return me.Data.Data;
    };

    function prepareElement(elem) {
        if (!elem) {
            return;
        }

        elem = Tools.Escape.Element(elem);

        var parentElement = this.Data.DataMap[elem.ParentOID];

        if (elem.ParentOID && !parentElement) {
            delete elem.ParentOID;
        }

        if (elem.Type !== Enums.elementType.Location && elem.Type < 100 &&
            parentElement && parentElement.Type === Enums.elementType.Root) {
            delete elem.ParentOID;
            parentElement = null;
        }

        if (!elem.ParentOID) {
            this.Data.Root = elem;

            return elem;
        }

        if (elem.Type >= Enums.elementType.Checkbox) {
            elem.Evaluation = (elem.Evaluation || []).map(prepareValueRange);
            elem.Evaluation.sort(Tools.SortByOrder);

            elem.Actions = (elem.Actions || []).map(prepareWorkflow);
            elem.Actions.sort(Tools.SortByOrder);
        }

        if (parentElement) {
            elem.Parent = parentElement;

            if (elem.Type >= Enums.elementType.Checkbox) {
                updateCheckpointRelations(elem, parentElement);
            } else if (Tools.contains(Enums.elementType.AllGroups, elem.Type)) {
                updateCheckpointGroupRelations(elem, parentElement);
            } else if (elem.Type !== Enums.elementType.Root) {
                updateGenericRelations(elem, parentElement);
            }
        }

        return elem;
    }

    function prepareValueRange(valueRange) {
        if (!valueRange.CategoryOID) {
            return;
        }

        valueRange.OID = valueRange.OID || uuid();
        valueRange.CategoryOID = valueRange.CategoryOID.toLowerCase();
        valueRange.Order = valueRange.Order || 0;

        return valueRange;
    }

    function prepareWorkflow(workflow) {
        if (!workflow.CategoryOID &&
            workflow.Type !== Enums.elementActionType.OverdueNotification) {
            return;
        }

        workflow.OID = workflow.OID || uuid();
        workflow.Order = workflow.Order || 0;

        if (workflow.CategoryOID) {
            workflow.CategoryOID = workflow.CategoryOID.toLowerCase();
        }

        return workflow;
    }

    function updateCheckpointRelations(checkpoint, group) {
        if (!(checkpoint && group)) {
            return;
        }

        group.Parameters = group.Parameters || [];
        group.Parameters.push(checkpoint);
        group.Parameters.sort(Tools.SortByPosition);
    }

    function updateCheckpointGroupRelations(group, parent) {
        if (!(group && parent)) {
            return;
        }

        group.Collapsed = false;
        parent.Parametergroups = parent.Parametergroups || [];
        parent.Parametergroups.push(group);
        parent.Parametergroups.sort(Tools.SortByPosition);
    }

    function updateGenericRelations(elem, parent) {
        if (!(elem && parent)) {
            return;
        }

        parent.Children = parent.Children || [];
        parent.Children.push(elem);
        parent.Children.sort(Tools.SortByPosition);
    }

    global.ElementLoader = ElementLoader;
})(window.Tools || (window.Tools = {}));