/**
 * @require ./tools.js
 */
(function (global) {
    var WindowWrappers = {};

    WindowWrappers.ShowFormSelectionForLocation = function (options) {
        Tools.Spinner.show();
        Tools.DataManager.FormLoader
            .GetAll()
            .then(function (formData) {
                Tools.Spinner.hide();

                var location = options.Location ? options.Location : CurrentEntity;

                options = options || {};

                options.showAssignedFormsOnly = typeof options.showAssignedFormsOnly === 'boolean' ?
                    options.showAssignedFormsOnly :
                    Tools.GetOfficeSettingValue('ShowOnlyAssignedForms') ||
                    !ressources.users.hasRightAtLocation(Enums.Rights.CurrentModeRights.CreateAnyForm, location.OID);

                options.showCheckbox = typeof options.showCheckbox === 'boolean' ?
                    options.showCheckbox :
                    false;

                options.selectMultiple = typeof options.selectMultiple === 'boolean' ?
                    options.selectMultiple :
                    false;

                options.selectedForms = options.selectedForms || [];
                options.onApplySelection = options.onApplySelection || $.noop;

                var formInformation = Tools.GetAvailableAndVisibleForms(
                    formData.Map,
                    location,
                    options.disableSurveys,
                    options.disableInvestigations
                );

                var customActionButton = ressources.users.hasRightAtLocation(Enums.Rights.CurrentModeRights.CreateAnyForm, location.OID) ?
                    {
                        title: options.showAssignedFormsOnly ?
                            options.buttons.showAllForms :
                            options.buttons.showAssignedFormsOnly,
                        onClick: function (evt) {
                            var $this = $(evt.currentTarget);

                            options.showAssignedFormsOnly = !options.showAssignedFormsOnly;

                            TreePicker.Close();
                            Tools.WindowWrappers.ShowFormSelectionForLocation(options);

                            $this.text(
                                options.showAssignedFormsOnly ?
                                    options.buttons.showAllForms :
                                    options.buttons.showAssignedFormsOnly
                            );

                            Tools.UpdateOfficeUserSettings('ShowOnlyAssignedForms', options.showAssignedFormsOnly);
                        }
                    } :
                    null;

                var checkbox = options.showCheckbox ?
                    {
                        fullrow: true,
                        disabled: function (node) {
                            if (!node) {
                                return false;
                            }

                            var form = node.entity;

                            if (options.disableSurveys && form.IsSurvey) {
                                return true;
                            }

                            if (options.disableInvestigations && form.IsInvestigation) {
                                return true;
                            }

                            return options.showAssignedFormsOnly &&
                                !formInformation.AvailableForms[form.OID];
                        }
                    } :
                    undefined;

                var isSelectable = !options.showCheckbox ?
                    function (form) {
                        if (!form) {
                            return false;
                        }

                        if (options.disableSurveys && form.IsSurvey) {
                            return false;
                        }

                        if (options.disableInvestigations && form.IsInvestigation) {
                            return false;
                        }

                        var isSelectable = !options.showAssignedFormsOnly ||
                            formInformation.AvailableForms[form.OID] || false;

                        if (!isSelectable || !options.disableEmptyForms) {
                            return isSelectable;
                        }

                        return form.HasCheckpoints;
                    } : undefined;

                var pickerOptions = {
                    title: options.windowCaption,
                    selectMultiple: options.selectMultiple,
                    onApply: options.showCheckbox || options.selectMultiple ? options.onApplySelection : null,
                    onSelect: !(options.showCheckbox || options.selectMultiple) ? options.onApplySelection : null,
                    checkedEntities: options.selectedForms,
                    customActionButton: customActionButton,
                    treeOptions: {
                        schema: {id: 'OID', children: 'Children', text: 'Title', color: 'Color'},
                        opened: true,
                        checkbox: checkbox,
                        selectable: isSelectable,
                        filter: function (form) {
                            if (!options.showAssignedFormsOnly) {
                                return form.Enabled;
                            }

                            if (options.disableSurveys && form.IsSurvey) {
                                return false;
                            }

                            if (options.disableInvestigations && form.IsInvestigation) {
                                return false;
                            }

                            return form.Enabled && formInformation.VisibleForms[form.OID];
                        }
                    }
                };

                TreePicker.Show(formData.Root, pickerOptions);
            }, function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                Tools.Spinner.hide();
            });
    };

    WindowWrappers.ShowSchedulingSelectionForLocation = function (options) {
        Tools.Spinner.show();
        Tools.DataManager.SchedulingLoader
            .GetAll()
            .then(function (data) {
                Tools.Spinner.hide();

                var location = options.Location ? options.Location : CurrentEntity;

                options = options || {};

                options.showAssignedSchedulingsOnly = typeof options.showAssignedSchedulingsOnly === 'boolean' ?
                    options.showAssignedSchedulingsOnly :
                    Tools.GetOfficeSettingValue('ShowOnlyAssignedSchedulings') ||
                    !ressources.users.hasRightAtLocation(Enums.Rights.CurrentModeRights.ShowAllSchedulings, location.OID);

                options.showCheckbox = typeof options.showCheckbox === 'boolean' ?
                    options.showCheckbox :
                    false;

                options.selectMultiple = typeof options.selectMultiple === 'boolean' ?
                    options.selectMultiple :
                    false;

                options.selectedSchedulings = options.selectedSchedulings || [];
                options.onApplySelection = options.onApplySelection || $.noop;

                Tools.GetAvailableAndVisibleSchedulings(data.Map, location, options.showAssignedSchedulingsOnly)
                    .then(function (schedulingInformation) {
                        var customActionButton = ressources.users.hasRightAtLocation(Enums.Rights.CurrentModeRights.ShowAllSchedulings, location.OID) ?
                            {
                                title: options.showAssignedSchedulingsOnly ?
                                    options.buttons.showAllSchedulings :
                                    options.buttons.showAssignedSchedulingsOnly,
                                onClick: function (evt) {
                                    var $this = $(evt.currentTarget);

                                    options.showAssignedSchedulingsOnly = !options.showAssignedSchedulingsOnly;

                                    TreePicker.Close();
                                    Tools.WindowWrappers.ShowSchedulingSelectionForLocation(options);

                                    $this.text(
                                        options.showAssignedSchedulingsOnly ?
                                            options.buttons.showAllSchedulings :
                                            options.buttons.showAssignedSchedulingsOnly
                                    );

                                    Tools.UpdateOfficeUserSettings(
                                        'ShowOnlyAssignedSchedulings',
                                        options.showAssignedSchedulingsOnly
                                    );
                                }
                            } :
                            null;

                        var checkbox = options.showCheckbox ?
                            {
                                fullrow: true,
                                disabled: function (node) {
                                    if (!node) {
                                        return false;
                                    }

                                    var scheduling = node.entity;

                                    return options.showAssignedSchedulingsOnly &&
                                        !schedulingInformation.AvailableSchedulings[scheduling.OID];
                                }
                            } :
                            undefined;

                        var isSelectable = !options.showCheckbox ?
                            function (scheduling) {
                                if (!scheduling) {
                                    return false;
                                }

                                return !options.showAssignedSchedulingsOnly ||
                                    schedulingInformation.AvailableSchedulings[scheduling.OID];
                            } : undefined;

                        var pickerOptions = {
                            title: options.windowCaption,
                            selectMultiple: options.selectMultiple,
                            onApply: options.showCheckbox || options.selectMultiple ? options.onApplySelection : null,
                            onSelect: !(options.showCheckbox || options.selectMultiple) ? options.onApplySelection : null,
                            checkedEntities: options.selectedSchedulings,
                            customActionButton: customActionButton,
                            treeOptions: {
                                schema: {id: 'OID', children: 'Children', text: 'Title', color: 'Color'},
                                opened: true,
                                checkbox: checkbox,
                                selectable: isSelectable,
                                filter: function (scheduling) {
                                    return !options.showAssignedSchedulingsOnly ||
                                        schedulingInformation.VisibleSchedulings[scheduling.OID];
                                }
                            }
                        };

                        TreePicker.Show(data.Root, pickerOptions);
                    }, function (xhr) {
                        Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                        Tools.Spinner.hide();
                    });
            }, function (xhr) {
                Tools.handleHttpError(Enums.HttpActionType.Read, xhr);
                Tools.Spinner.hide();
            });
    };

    WindowWrappers.ShowStatusSelection = function (options) {
        options = options || {};

        options.showCheckbox = typeof options.showCheckbox === 'boolean' ?
            options.showCheckbox :
            false;

        options.selectMultiple = typeof options.selectMultiple === 'boolean' ?
            options.selectMultiple :
            false;

        options.selectedStatusses = options.selectedStatusses || [];
        options.onApplySelection = options.onApplySelection || $.noop;

        var statusInformation = Tools.GetAvailableAndVisibleStatusses(options.statusState, options.additionalFilter);

        var checkbox = options.showCheckbox ?
            {
                fullrow: true,
                disabled: function (node) {
                    if (!node) {
                        return false;
                    }

                    var status = node.entity;

                    return !statusInformation.AvailableStatusses[status.OID];
                }
            } :
            undefined;

        var isSelectable = !options.showCheckbox ?
            function (status) {
                return status && statusInformation.AvailableStatusses[status.OID];
            } : undefined

        var pickerOptions = {
            title: options.windowCaption,
            selectMultiple: options.selectMultiple,
            onApply: options.showCheckbox || options.selectMultiple ? options.onApplySelection : null,
            onSelect: !(options.showCheckbox || options.selectMultiple) ? options.onApplySelection : null,
            checkedEntities: options.selectedStatusses,
            treeOptions: {
                schema: { id: 'OID', children: 'Children', text: 'Title', color: 'Color' },
                opened: true,
                checkbox: checkbox,
                selectable: isSelectable,
                filter: function (status) {
                    return statusInformation.VisibleStatusses[status.OID];
                }
            }
        };

        TreePicker.Show(IssueStates, pickerOptions);
    };

    WindowWrappers.ShowRolesSelection = function (options) {
        var selections = (options.selectedRoles || []).concat(options.inheritedRoles || []);
        var treeOptions = {
            schema: function (role) {
                var isChecked = selections.includes(role.OID);
                var isDisabled = (options.inheritedRoles || []).includes(role.OID);
                var checkbox = role.OID === '#' ? false : { checked: isChecked, disabled: isDisabled, fullrow: true };

                return {
                    id: role.OID,
                    text: role.Title,
                    children: role.Children,
                    checkbox: checkbox
                };
            },
            filter: function (role) { return role.ModificationType !== Enums.ModificationType.Deleted; }
        };

        var roles = Object
            .keys(options.dataSource)
            .map(function (oid) { return options.dataSource[oid]; })
            .sort(Tools.SortByTitle);

        var treePickerOptions = {
            title: options.title,
            treeOptions: treeOptions,
            onApply: options.onSelect
        };

        TreePicker.Show(roles, treePickerOptions);
    };

    return (global.WindowWrappers = WindowWrappers);
})(window.Tools || (window.Tools = {}));