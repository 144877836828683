$.fn.ContextMenu = function (options) {
    // options - Form --------------
    // selector : string (optional)
    // scrollableContainerSelector : string (optional)
    // click: function(element)
    // zIndex: number (optional)
    // structure : array[object]
    // [
    //   {
    //      type: int (1 = Button, 2 = Line)
    //      title: string or string function()
    //      icon: string(imageurl)
    //      visible: boolean function($element)
    //      disabled: boolean function($element)
    //      click: function()
    //   }
    //]
    // --------------------------
    if (this.length <= 0) {
        return;
    }

    var $this = $(this);

    if (options.selector) {
        $this.on('contextmenu', options.selector, function (event) {
            contextmenu.show(this, event, options);

            return false;
        });
    } else {
        $this.on('contextmenu', function (event) {
            contextmenu.show(this, event, options);

            return false;
        });
    }

    if (options.scrollableContainerSelector) {
        var $scrollableContainer = $this.parents(options.scrollableContainerSelector);

        if (!$scrollableContainer.length) {
            $scrollableContainer = $this.find(options.scrollableContainerSelector);
        }

        $scrollableContainer.on('scroll', function() {
            contextmenu.close();
        });
    } else {
        $this.on('scroll', function() {
            contextmenu.close();
        });
    }
};

var contextmenu = {
    options: null,
    element: null,
    $element: null,
    clipboard: {},
    show: function (element, event, options) {
        var $contextmenu = $('#gfx-contextmenu');
        var $items = $('<div class="c-co">');
        var position = { top: event.pageY, left: event.pageX };
        var height;
        var anyItemVisible = false;

        if (options.enabled === false ||
                typeof options.enabled === 'function' &&
                !options.enabled(element, event)) {
            return this;
        }

        this.options = options;
        this.element = element;
        this.$element = $(element);

        if (options.show) {
            options.show(element, event);
        }

        $('body').on('click.hideContextmenu contextmenu.hideContextmenu', this.close);

        var self = this;
        (options.items || []).forEach(function (item) {
            var isDisabled, title, $item;

            if (typeof item.visible === 'function' && !item.visible(self.$element)) {
                return;
            }

            if (item.type === 1) {
                isDisabled = typeof item.disabled === 'function' && item.disabled(self.$element);
                title = item.title instanceof Function ? item.title() : item.title;

                $item = $('<div class="c-co-el"{1}>{0}</div>'.format(title, isDisabled ? ' disabled' : ''));

                if (item.icon) {
                    $item.append('<div class="c-co-ic" style="background-image: url(\'{0}\')"></div>'.format(item.icon));
                }

                if (item.styles) {
                    $item.find('.c-co-ic').css(item.styles);
                }


                if (!isDisabled) {
                    $item.on('click.e', item.click);
                    $item.on('click.c', contextmenu.close);
                }

                $items.append($item);
                anyItemVisible = true;
            } else if (item.type === 2) {
                $items.append('<div class="c-co-li"></div>');
            }
        });

        if (!anyItemVisible) {
            $contextmenu.addClass('hide');
            return this;
        }

        $contextmenu
            .empty()
            .append($items)
            .removeClass('hide');

        height = $contextmenu.height();

        if (position.top + height > window.innerHeight) {
            position.top -= height;
        }

        if (options.useSelectorAsContainer && event.target) {
            $contextmenu.detach();

            $(event.target)
                .css('position', 'relative')
                .append($contextmenu);

            $contextmenu.css({ top: event.offsetY, left: event.offsetX });
        } else if (!$contextmenu.parent().is('body')) {
            $contextmenu.detach();

            $('body').append($contextmenu);

            $contextmenu.css(position);
        } else {
            $contextmenu.css(position);
        }

        $contextmenu.css('zIndex', options.zIndex == null || isNaN(options.zIndex) ?
            '' :
            options.zIndex);

        return this;
    },
    close: function () {
        var $contextMenu = $('#gfx-contextmenu');

        $contextMenu.addClass('hide');

        if (contextmenu.options) {
            if (contextmenu.options.useSelectorAsContainer) {
                $contextMenu.parent().css('position', '');
                $contextMenu.detach();

                $('body').append($contextMenu);
            }

            if (contextmenu.options.hide) {
                contextmenu.options.hide();
            }
        }

        $('body').off('click.hideContextmenu contextmenu.hideContextmenu');

        return this;
    },
    setClipboardData: function (clipboardid, data) {
        // data - Form --------------
        // element : object (to copy or cut)
        // mode : int (example : 1 = copy, 2 = cut)
        // --------------------------
        this.clipboard[clipboardid] = data;

        return this;
    },
    getClipboardData: function (clipboardid) {
        return this.clipboard[clipboardid];
    },
    wipeClipboardData: function () {
        if (!this.clipboard) {
            return this;
        }

        for (var clipboardId in this.clipboard) {
            delete this.clipboard[clipboardId];
        }

        return this;
    }
};

$('#gfx-contextmenu').on('click.preventClose', function (event) {
    event.stopPropagation();
    event.preventDefault();

    return false;
});